import { IAnalyticSlice } from "@/types/analytics";

export const aggregateAnalytics = (
  list: IAnalyticSlice[],
): Record<string, IAnalyticSlice> => {
  return list.reduce((acc: any, item: IAnalyticSlice) => {
    const parentId = item.parentId || "";
    if (!acc[parentId]) {
      acc[parentId] = { id: parentId, views: 0, share: 0 };
    }
    acc[parentId].views += item.views;
    acc[parentId].share += item.share;
    return acc;
  }, {} as IAnalyticSlice);
};
