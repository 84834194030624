import { FC, useCallback, useState } from "react";
import { ProjectNav } from "@/features/projects/components/ProjectNav";
import { IClientProject } from "@/features/client/types";
import { WordKeys } from "@/constants/WordKeys";
import { TFunction } from "i18next";
import classNames from "classnames";
import styles from "./ProjectList.module.scss";

interface IProjectListProps {
  isOpen: boolean;
  projects?: IClientProject[];
  t: TFunction;
  onSwitchSidebar: () => void;
}

export const ProjectList: FC<IProjectListProps> = ({
  isOpen,
  projects,
  t,
  ...props
}) => {
  const [isDefaultOpened, setIsDefaultOpened] = useState(false);

  const handleSetIsDefaultCollapsed = useCallback((value: boolean) => {
    setIsDefaultOpened(value);
  }, []);

  return (
    <div className={classNames(styles.root, isOpen || styles.closed)}>
      {projects?.map((project) => (
        <ProjectNav
          isDefaultOpened={isDefaultOpened}
          setIsDefaultOpened={handleSetIsDefaultCollapsed}
          key={project._id}
          className={styles.item}
          isOpen={isOpen}
          projectData={project}
          {...props}
        />
      ))}
      <div className={styles.w100}>
        {!projects?.length && <p>{t(WordKeys.errors.projectsNotFound)}</p>}
      </div>
    </div>
  );
};
