import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Outlet } from "react-router-dom";
import { Header } from "@/components/Header";
import { Footer } from "@/components/Footer";
import { Sidebar } from "@/components/Sidebar";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { useAppLayout } from "@/components/AppLayout/useAppLayout";
import { useScrollLock } from "@/hooks/useSkrollLock";
import styles from "./AppLayout.module.scss";

export const AppLayout: FC = () => {
  const { enableScroll, disableScroll } = useScrollLock();
  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const { isMobile } = useWindowSize(1200);
  const { isMobile: isMobileDefault } = useWindowSize();
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const { clientData, isLoading } = useAppLayout();

  const isPadding = useMemo(
    () => isMobileDefault && parsedSearchParams?.storyId,
    [isMobileDefault, parsedSearchParams?.storyId],
  );

  useEffect(() => {
    setIsSidebarOpen(() => {
      if (!isMobile) return true;
      if (!clientData) return !isMobile;

      return clientData?.projects?.length > 1;
    });
  }, [clientData, isMobile]);

  useEffect(() => {
    if (isMobile) {
      isSidebarOpen ? disableScroll() : enableScroll();
    }
  }, [disableScroll, enableScroll, isMobile, isSidebarOpen]);

  const handleSwitchSidebarOpen = useCallback(() => {
    setIsSidebarOpen((prev) => !prev);
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Sidebar
          isLoading={isLoading}
          isOpen={isSidebarOpen}
          onSwitchSidebar={handleSwitchSidebarOpen}
          projects={clientData?.projects}
          clientId={clientData?._id}
        />
        <div className={styles.contentWrapper}>
          <Header
            clientData={clientData}
            onSwitchSidebar={handleSwitchSidebarOpen}
          />
          <main className={styles.content}>
            <Outlet />
          </main>
        </div>
      </div>
      <Footer
        className={isPadding ? styles.rootSpace : ""}
        clientData={clientData}
      />
    </div>
  );
};
