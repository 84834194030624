import { FC, useCallback, useMemo } from "react";
import { IStory, IStoryResponse } from "@/features/stories/types";
import { StoryAnalytic } from "@/features/stories/components/StoryAnalytic";
import { Badge } from "@/components/Badge/Badge";
import { useAnalytic } from "@/hooks/useAnalytic";
import { PlayButton } from "@/components/PlayButton";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { transformTime } from "@/helpers/transformTime";
import { MarkdownText } from "@/components/MarkdownText";
import classNames from "classnames";
import dialogStyles from "@/styles/DialogContent.module.scss";
import styles from "./StoryDialog.module.scss";

interface IRouteDialogProps {
  className?: string;
  isPlayBtn?: boolean;
  onClose?: () => void;
  storyData: IStory | IStoryResponse;
}

export const StoryDialog: FC<IRouteDialogProps> = ({
  className = "",
  isPlayBtn,
  onClose,
  storyData,
}) => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const isPlayCurrent = useMemo(
    () =>
      storyData?._id === parsedSearchParams?.storyId &&
      parsedSearchParams?.isPlay,
    [parsedSearchParams?.isPlay, parsedSearchParams?.storyId, storyData?._id],
  );
  const { currentAnalytic } = useAnalytic(storyData?._id || "");

  const handlePlayClick = useCallback(() => {
    if (isPlayCurrent) {
      mergeSearchParams({ isPlay: undefined });
    } else {
      mergeSearchParams({
        storyId: storyData?._id,
        isPlay: true,
      });
    }
  }, [isPlayCurrent, mergeSearchParams, storyData]);

  return (
    <div className={classNames(dialogStyles.root, className)}>
      <img
        className={dialogStyles.img}
        src={storyData.image_path}
        alt={"Active story"}
      />
      <div className={dialogStyles.body}>
        <div className={dialogStyles.header}>
          <div className={styles.boxStart}>
            {"order" in storyData && storyData?.order && (
              <Badge content={storyData.order} />
            )}
            <h4 className={styles.title}>{storyData.name}</h4>
          </div>
          <div className={dialogStyles.boxSb}>
            <StoryAnalytic
              views={currentAnalytic?.views}
              share={currentAnalytic?.share}
              storyId={storyData._id}
            />
            {isPlayBtn && (
              <PlayButton
                name={transformTime(storyData?.audio_length)}
                onClick={handlePlayClick}
                isPlay={isPlayCurrent}
              />
            )}
          </div>
        </div>
        <div className={dialogStyles.description}>
          <MarkdownText
            description={storyData.description}
            action={onClose}
          ></MarkdownText>
        </div>
      </div>
    </div>
  );
};
