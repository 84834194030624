import qs from "qs";

export const MAIN_PAGE_ROOT = "/main";
export const ROUTE_PAGE_ROOT = "/route";
export const STORY_PAGE_ROOT = "/story";

export const ROUTES = {
  main: {
    path: `${MAIN_PAGE_ROOT}/:clientId`,
    createURL: (
      clientId: string,
      searchParams?: Record<string, string | boolean | undefined>,
    ) => {
      const searchString = qs.stringify(searchParams, { addQueryPrefix: true });

      return `${ROUTES.main.path.replace(":clientId", clientId)}${searchString}`;
    },
  },
  mainRoute: {
    path: `${MAIN_PAGE_ROOT}/:clientId/route/:routeId`,
    createURL: (clientId: string, routeId: string) => {
      return ROUTES.mainRoute.path
        .replace(":clientId", clientId)
        .replace(":routeId", routeId);
    },
  },
  mainStory: {
    path: `${MAIN_PAGE_ROOT}/:clientId/story/:storyId`,
    createURL: (clientId: string, storyId: string) => {
      return ROUTES.mainStory.path
        .replace(":clientId", clientId)
        .replace(":storyId", storyId);
    },
  },
  route: {
    path: `${ROUTE_PAGE_ROOT}/:routeId`,
    createURL: (routeId: string) => {
      return ROUTES.route.path.replace(":routeId", routeId);
    },
  },
  story: {
    path: `${STORY_PAGE_ROOT}/:storyId`,
    createURL: (storyId: string) => {
      return ROUTES.story.path.replace(":storyId", storyId);
    },
  },
  errorPage: {
    path: "/error-page",
    createURL: () => {
      return ROUTES.errorPage.path;
    },
  },
};
