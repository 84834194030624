export enum PlayerSpeedEnum {
  First = 1.0,
  Second = 1.25,
  Third = 1.5,
  Fourth = 2.0,
}

export const PlayerSpeed = [
  {
    label: "1x",
    value: PlayerSpeedEnum.First,
  },
  {
    label: "1.25x",
    value: PlayerSpeedEnum.Second,
  },
  {
    label: "1.5x",
    value: PlayerSpeedEnum.Third,
  },
  {
    label: "2x",
    value: PlayerSpeedEnum.Fourth,
  },
];
