import { useTranslation } from "react-i18next";
import { useInitialData } from "@/hooks/useInitialData";
import { useActiveData } from "@/hooks/useActiveData";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { useMainPage } from "@/pages/main/useMainPage";
import { useProject } from "@/pages/main/useProject";

export const useAppLayout = () => {
  const { t } = useTranslation();
  const { parsedSearchParams, refreshSearchParams } =
    useSearchParams<ISearchParams>();
  const { projectId } = parsedSearchParams;

  const { data: clientData, isLoading } = useInitialData(t);

  useActiveData();

  const { activeClientProject } = useMainPage({
    clientData,
    refreshSearchParams,
    projectId,
    skipRefreshParams: true
  });

  useProject(activeClientProject?._id);

  return { clientData, isLoading };
};
