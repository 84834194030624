import {
  IProject,
  IProjectResponse,
  IProjectRoute,
  IRouteOrder,
} from "@/features/projects/types";
import { getImgUrl } from "@/helpers/getImgUrl";

const sortRoutes = (
  routesOrder?: IRouteOrder[],
  routesInfo?: IProjectRoute[],
): IProjectRoute[] | [] => {
  if (!routesOrder || !routesInfo) {
    return [];
  }

  const routesInfoById: Record<string, IProjectRoute> = {};
  const routes: IProjectRoute[] = [];

  routesInfo?.forEach((routeInfo) => {
    routesInfoById[routeInfo._id] = routeInfo;
  });

  routesInfo?.sort(
    (a: IProjectRoute, b: IProjectRoute) => a.topsort - b.topsort,
  );
  routesOrder?.sort((a: IRouteOrder, b: IRouteOrder) => a.order - b.order);

  routesOrder?.forEach((routeOrder) => {
    const route: IProjectRoute | undefined = routesInfoById[routeOrder.id];
    const isDuplicate = routes.find((item) => item._id === routeOrder?.id);

    if (route && !isDuplicate) {
      const newRoute: IProjectRoute = {
        ...route,
        image_path: getImgUrl(route.image_path),
        image_tn_path: getImgUrl(route.image_tn_path),
      };

      routes.push(newRoute);
    }
  });

  return routes;
};

export const ProjectMapper = (response: IProjectResponse): IProject => {
  return {
    ...response,
    sortRoutes: sortRoutes(response.routes, response.routes_info),
  };
};
