import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLazyGetProjectsQuery } from "@/features/projects/projectsApi";
import { useEffect } from "react";
import {
  setActiveProject,
  setProjectLoading,
} from "@/store/activeProjectSlice";
import { handleError } from "@/helpers/handleError";
import { WordKeys } from "@/constants/WordKeys";
import { setAnalyticData } from "@/store/analyticSlice";
import { aggregateAnalytics } from "@/helpers/aggregateAnalytics";
import { RootState } from "@/store/store";

export const useProject = (projectId: string) => {
  const { data: projectData } = useSelector(
    (state: RootState) => state.activeProjectData,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [getProject, { data, isFetching, error }] = useLazyGetProjectsQuery();

  useEffect(() => {
    const isIncluded = projectData?.find((item) => item._id === projectId);

    if (projectId && !isIncluded) {
      getProject({ projectId });
    }
  }, [getProject, projectData, projectId]);

  useEffect(() => {
    if (data) {
      dispatch(setActiveProject(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setProjectLoading(isFetching));
  }, [dispatch, isFetching]);

  useEffect(() => {
    if (data) {
      const list: any[] = data?.sortRoutes?.map((item) => ({
        id: item?._id,
        parentId: projectId,
        views: item?.plays || 0,
        share: item?.shares || 0,
      }));

      const projectAnalytic = aggregateAnalytics(list)?.[projectId];

      projectAnalytic && list.push(projectAnalytic);

      // dispatch projects and routes
      dispatch(setAnalyticData(list));
    }
  }, [data, dispatch, projectId]);

  useEffect(() => {
    if (error) {
      handleError(error, t(WordKeys.errors.defaultErrorMessage));
    }
  }, [error, t]);

  return {
    isLoading: isFetching,
  };
};
