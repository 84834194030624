import { FC, useCallback } from "react";
import { ProjectDescription } from "@/features/projects/components/ProjectDescription";
import { ChildrenPositionEnum } from "@/types/position";
import { ProjectView } from "@/features/projects/ProjectView";
import { IClientProject } from "@/features/client/types";
import { useDialog } from "@/hooks/useDialog";
import { IDetailsDialogContentType } from "@/components/DetailsDialog/DetailsDialog";
import { WordKeys } from "@/constants/WordKeys";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "@/hooks/useWindowSize";
import styles from "./ProjectMainView.module.scss";

interface IProjectMainProps {
  project: IClientProject;
}

export const ProjectMain: FC<IProjectMainProps> = ({ project }) => {
  const { isMobile } = useWindowSize();
  const { dialogContext } = useDialog();
  const { t } = useTranslation();

  const handleOpenDialog = useCallback(() => {
    if (project) {
      dialogContext.setDialogContent({
        type: IDetailsDialogContentType.ProjectDetails,
        data: project,
      });

      dialogContext.toggleDialog();
    }
  }, [dialogContext, project]);

  return (
    <>
      {isMobile ? (
        <ProjectDescription
          className={styles.projectDescription}
          projectData={project}
          childrenPosition={ChildrenPositionEnum.Bottom}
        >
          <button className={styles.readmore} onClick={handleOpenDialog}>
            {t(WordKeys.routeView.readMore)}
          </button>
        </ProjectDescription>
      ) : (
        <ProjectView className={styles.contentView} projectData={project} />
      )}
    </>
  );
};
