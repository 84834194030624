import { FC, useEffect, useRef } from "react";
import { IProject } from "@/features/projects/types";
import { RouteActive } from "@/features/routes/components/RouteActive";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import styles from "./RouteSlider.module.scss";
import classNames from "classnames";

interface IRouteSlider {
  className?: string;
  data: IProject;
}

export const RouteSlider: FC<IRouteSlider> = ({ className = "", data }) => {
  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const activeRouteRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeRouteRef.current) {
      activeRouteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [parsedSearchParams?.routeId, activeRouteRef.current]);

  return (
    <div
      className={classNames(
        styles.root,
        data?.sortRoutes?.length === 1 && styles.fullWidth,
        className,
      )}
    >
      {data?.sortRoutes?.map((route, index) => (
        <div
          key={route?._id + index}
          className={styles.slide}
          ref={
            parsedSearchParams?.routeId === route?._id ? activeRouteRef : null
          }
        >
          <RouteActive
            className={styles.routeNav}
            routeData={route}
            isActive={parsedSearchParams?.routeId === route?._id}
          />
        </div>
      ))}
    </div>
  );
};
