import { FC } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import styles from "../StoryMarker/StoryMarker.module.scss";

interface IUserMarkerProps {
  coordinate: google.maps.LatLngLiteral;
}

export const UserMarker: FC<IUserMarkerProps> = ({ coordinate }) => {
  return (
    <AdvancedMarker className={styles.root} position={coordinate}>
      <div className={styles.dot}></div>
    </AdvancedMarker>
  );
};
