import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import {
  getLinks,
  IFooterLink,
  LogoList,
  SocialList,
  StoreList,
} from "@/components/Footer/constants";
import { IClient } from "@/features/client/types";
import classNames from "classnames";
import styles from "./Footer.module.scss";

interface IFooterProps {
  className?: string;
  clientData?: IClient | null;
}

export const Footer: FC<IFooterProps> = ({ className = "", clientData }) => {
  const { t } = useTranslation();

  const getListNode = useCallback((list: IFooterLink[], className = "") => {
    return list.map(
      ({ imgSrc, href, alt }) =>
        href && (
          <li className={className} key={href}>
            <a
              className={styles.link}
              href={href}
              target="_blank"
              rel="noreferrer"
            >
              <img src={imgSrc} alt={alt} />
            </a>
          </li>
        ),
    );
  }, []);

  return (
    <footer className={classNames(styles.root, className)}>
      <div className={styles.content}>
        <div className={styles.item}>
          <ul className={classNames(styles.list, styles.stores)}>
            {getListNode(getLinks(StoreList, clientData))}
          </ul>
        </div>
        <div className={styles.item}>
          <ul className={classNames(styles.list, styles.logos)}>
            {getListNode(getLinks(LogoList, clientData))}
          </ul>
        </div>
        <div className={styles.item}>
          <ul className={classNames(styles.list, styles.socials)}>
            <li>
              <a
                className={styles.link}
                href={clientData?.footer_url}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className={styles.clientLogo}
                  src={clientData?.footer_image_path}
                  alt={clientData?.name}
                />
              </a>
            </li>
            {getListNode(getLinks(SocialList, clientData))}
          </ul>
        </div>
      </div>

      <div className={styles.info}>
        <p className={styles.text}>
          {clientData?.footer || t(WordKeys.copyright)}
        </p>
      </div>
    </footer>
  );
};
