import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { PlayButton } from "@/components/PlayButton";
import { StoryAnalytic } from "@/features/stories/components/StoryAnalytic";
import { LongDescription } from "@/components/LongDescription";
import { Badge } from "@/components/Badge/Badge";
import { IStory } from "@/features/stories/types";
import { transformTime } from "@/helpers/transformTime";
import { useImageDialog } from "@/hooks/useImageDialog";
import { useAnalytic } from "@/hooks/useAnalytic";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setPlayerTime } from "@/store/playerTimeSlice";
import ChevronIcon from "@/img/icons/chevron_bottom_blue.svg";
import classNames from "classnames";
import styles from "./StoryNav.module.scss";

interface IStoryNavProps {
  className?: string;
  storyData: IStory;
  collapseIdActive?: string;
  onToggleCollapse: (id?: string) => void;
}

export const StoryNav: FC<IStoryNavProps> = ({
  className = "",
  storyData,
  collapseIdActive,
  onToggleCollapse,
}) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useWindowSize();
  const { currentAnalytic } = useAnalytic(storyData?._id || "");
  const { openImageDialog } = useImageDialog();
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const { time: playerTime } = useSelector(
    (state: RootState) => state.playerData,
  );

  useEffect(() => {
    if (parsedSearchParams?.storyId === storyData?._id && !isMobile) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }, 300);
    }
  }, [isMobile, parsedSearchParams?.storyId, storyData?._id]);

  const isCollapsed = useMemo(
    () => collapseIdActive === storyData._id,
    [collapseIdActive, storyData._id],
  );

  const isActive = useMemo(
    () =>
      storyData?._id === parsedSearchParams?.storyId &&
      parsedSearchParams?.isPlay,
    [parsedSearchParams?.isPlay, parsedSearchParams?.storyId, storyData?._id],
  );

  const handlePlayClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (isActive) {
        mergeSearchParams({ isPlay: undefined });
      } else {
        mergeSearchParams({
          storyId: storyData?._id,
          isPlay: true,
        });
        dispatch(setPlayerTime(0));
      }
    },
    [dispatch, isActive, mergeSearchParams, storyData?._id],
  );

  const handleOnClickStory = useCallback(
    (toClose?: boolean) => {
      if (!isCollapsed) {
        onToggleCollapse(storyData._id);
      } else {
        if (isCollapsed && toClose) {
          onToggleCollapse();
        }
      }
    },
    [isCollapsed, onToggleCollapse, storyData._id],
  );

  const handleOnClickImage = useCallback(() => {
    openImageDialog(storyData?.image_path || "");
  }, [openImageDialog, storyData?.image_path]);

  return (
    <div
      ref={ref}
      className={classNames(
        styles.root,
        isCollapsed && styles.active,
        className,
      )}
      onClick={() => handleOnClickStory()}
    >
      <img
        onClick={handleOnClickImage}
        className={styles.img}
        src={storyData.image_tn_path}
        alt={"img: Project"}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.headerContent}>
            <Badge content={storyData.order} isActive={isCollapsed} />
            <h5 className={styles.title}>{storyData.name}</h5>
          </div>

          <button
            className={styles.collapse}
            onClick={() => handleOnClickStory(true)}
          >
            <img
              className={styles.collapseIcon}
              src={ChevronIcon}
              alt={"chevron"}
            />
          </button>
        </div>
        <LongDescription
          className={styles.description}
          description={storyData.description}
          isActive={isCollapsed}
        />
        <div className={styles.footer}>
          <StoryAnalytic
            share={currentAnalytic?.share}
            views={currentAnalytic?.views}
            storyId={storyData?._id}
          />
          <PlayButton
            name={
              isActive
                ? transformTime(playerTime || 0)
                : transformTime(storyData.audio_length)
            }
            onClick={(e: React.MouseEvent) => handlePlayClick(e)}
            isPlay={isActive}
            isActive={isCollapsed}
          />
        </div>
      </div>
    </div>
  );
};
