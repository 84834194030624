import { useCallback, useEffect, useState } from "react";

export const useUserCoordinates = (): google.maps.LatLngLiteral | null => {
  const [coordinates, setCoordinates] =
    useState<google.maps.LatLngLiteral | null>(null);

  const handleSetCoordinates = useCallback(
    (coordinates: google.maps.LatLngLiteral) => {
      setCoordinates(coordinates);
    },
    [],
  );

  useEffect(() => {
    const showPosition = (position: GeolocationPosition) => {
      const coordinates = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };

      handleSetCoordinates(coordinates);
    };

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(showPosition);
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, [handleSetCoordinates]);

  return coordinates;
};
