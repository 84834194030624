import { api } from "@/store/api";
import {
  IGetClientRequestParams,
  IGetClientResponseBody,
} from "@/features/client/types";
import { transformClientResponse } from "@/helpers/transformClientResponse";

const API_PREFIX = "/clients";

const clientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getClient: builder.query<IGetClientResponseBody, IGetClientRequestParams>({
      query: ({ id }) => ({
        url: `${API_PREFIX}/${id}`,
      }),
      transformResponse(response: IGetClientResponseBody): any {
        return transformClientResponse(response);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetClientQuery } = clientApi;
