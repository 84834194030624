import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProject } from "@/features/projects/types";

interface IProjectsState {
  data: null | IProject[];
  isLoading: boolean;
}

export const activeProjectSlice = createSlice({
  name: "activeProject",
  initialState: {
    data: null,
    isLoading: false,
  } as IProjectsState,
  reducers: {
    setData: (state, action: PayloadAction<null | IProject>) => {
      if (state.data === null) {
        state.data = [];
      }
      if (action.payload) {
        state.data.push(action.payload);
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setData: setActiveProject, setLoading: setProjectLoading } =
  activeProjectSlice.actions;
