import React from "react";
import "@/styles/index.scss";
import { Router } from "@/routing/Router";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { LoadingOverlayProvider } from "@/context/LoadingOverlayContext";
import { ImageDialogProvider } from "@/context/ImageDialogContext";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase";
import "react-toastify/dist/ReactToastify.css";

initializeApp(firebaseConfig);

function App() {
  return (
    <BrowserRouter>
      <LoadingOverlayProvider>
        <ImageDialogProvider>
          <Router />
        </ImageDialogProvider>
      </LoadingOverlayProvider>
      <ToastContainer position={"top-left"} />
    </BrowserRouter>
  );
}

export default App;
