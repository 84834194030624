import { FC } from "react";

interface IGetMarkerIconProps {
  className: string;
  fill: string;
}

export const getMarkerIcon: FC<IGetMarkerIconProps> = ({ className, fill }) => (
  <svg
    className={className}
    viewBox="0 0 72 88"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.1214 83.4776L36 85.0938L36.8786 83.4776L41.3282 75.2919C43.2267 71.7993 46.6433 69.375 50.4448 67.6291C62.3658 62.1542 70.6473 50.1091 70.6473 36.1276C70.6473 16.9925 55.1353 1.48047 36.0002 1.48047C16.8651 1.48047 1.35303 16.9925 1.35303 36.1276C1.35303 50.1089 9.63441 62.1539 21.5552 67.629C25.3566 69.3748 28.7731 71.7991 30.6716 75.2916L35.1214 83.4776Z"
      fill={fill}
      stroke="#C8C8C8"
      strokeWidth={2}
    />
  </svg>
);
