import React, { FC, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { AnalyticList } from "@/components/AnalyticList";
import { IProjectRoute } from "@/features/projects/types";
import { WordKeys } from "@/constants/WordKeys";
import { useAnalytic } from "@/hooks/useAnalytic";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import ArrowIcon from "@/img/icons/arrow_blue_left.svg";
import classNames from "classnames";
import styles from "./RouteNav.module.scss";

interface IRouteNavProps {
  className?: string;
  onSwitchSidebar?: () => void;
  routeData?: IProjectRoute;
}

export const RouteNav: FC<IRouteNavProps> = ({
  className = "",
  routeData,
  onSwitchSidebar,
}) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const { isMobile } = useWindowSize();
  const { currentAnalytic } = useAnalytic(
    routeData?._id || "",
    routeData?.stories?.length,
  );
  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const [isActive, setIsActive] = useState(false);
  const { routeId = "", projectId } = parsedSearchParams;

  useEffect(() => {
    setIsActive(routeId === routeData?._id?.toString());
  }, [routeData, routeId]);

  const handleOnClickRoute = useCallback(() => {
    if (clientId) {
      const path = ROUTES.main.createURL(clientId, {
        projectId: projectId,
        routeId: routeData?._id.toString(),
        playInRow: true,
      });
      navigate(path);
    }

    if (isMobile && onSwitchSidebar) {
      onSwitchSidebar();
    }
  }, [
    clientId,
    navigate,
    isMobile,
    onSwitchSidebar,
    projectId,
    routeData?._id,
  ]);

  return (
    <div
      className={classNames(styles.root, isActive && styles.active, className)}
      onClick={handleOnClickRoute}
    >
      <div className={styles.imgWrapper}>
        <img
          className={styles.img}
          src={routeData?.image_tn_path}
          alt={"Project"}
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.title}>{routeData?.name}</h3>
          <img className={styles.arrow} src={ArrowIcon} alt={"icon: Arrow"} />
        </div>
        <AnalyticList
          className={styles.list}
          analytics={currentAnalytic}
          countText={WordKeys.analytic.routesCount}
          routeId={routeData?._id}
        />
      </div>
    </div>
  );
};
