import { api } from "@/store/api";
import {
  IGetProjectsRequestParams,
  IGetProjectsResponseBody,
} from "@/features/projects/types";
import { ProjectMapper } from "@/helpers/projectMapper";

const API_PREFIX = "/projects";

const projectsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<
      IGetProjectsResponseBody,
      IGetProjectsRequestParams
    >({
      query: ({ projectId }) => ({
        url: `${API_PREFIX}/${projectId}`,
      }),
      transformResponse(response: IGetProjectsResponseBody): any {
        return ProjectMapper(response);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetProjectsQuery } = projectsApi;
