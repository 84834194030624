import React, { FC, useCallback, useEffect, useState } from "react";
import { RouteList } from "@/features/routes/components/RouteList";
import { AnalyticList } from "@/components/AnalyticList";
import { IClientProject } from "@/features/client/types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { useAnalytic } from "@/hooks/useAnalytic";
import { useActiveData } from "@/hooks/useActiveData";
import { Loading } from "@/components/Loading";
import { ROUTES } from "@/routing/constants";
import { useNavigate, useParams } from "react-router-dom";
import ArrowIcon from "@/img/icons/chevron_bottom_blue.svg";
import MenuIcon from "@/img/icons/menu.svg";
import classNames from "classnames";
import styles from "./ProjectNav.module.scss";

interface IProjectNavProps {
  className?: string;
  isOpen: boolean;
  projectData: IClientProject;
  onSwitchSidebar: () => void;
  isDefaultOpened: boolean;
  setIsDefaultOpened: (_: boolean) => void;
}

export const ProjectNav: FC<IProjectNavProps> = ({
  className = "",
  isOpen,
  projectData,
  onSwitchSidebar,
  isDefaultOpened,
  setIsDefaultOpened,
}) => {
  const navigate = useNavigate();
  const { clientId, ...params } = useParams();
  const { project, isProjectLoading } = useActiveData();
  const { currentAnalytic } = useAnalytic(
    projectData?._id,
    projectData?.routes?.length,
  );
  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const { projectId, routeId } = parsedSearchParams;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // only for main page
    if (!projectId && !Object.keys(params)?.length) {
      setIsDefaultOpened(true);
    }
  }, [params, projectId, setIsDefaultOpened]);

  useEffect(() => {
    setIsActive(projectId === projectData._id);
    !isDefaultOpened && setIsCollapsed(projectId === projectData._id);
  }, [isDefaultOpened, projectData._id, projectId]);

  const handleChangeIsCollapsed = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
    setIsCollapsed((prev) => !prev);
  }, []);

  const handleOnClickProject = useCallback(() => {
    setIsDefaultOpened(false);

    if (clientId) {
      const path = ROUTES.main.createURL(clientId, {
        projectId: projectData._id,
      });
      navigate(path);
    }
    setIsCollapsed(true);
  }, [navigate, clientId, setIsDefaultOpened, projectData._id]);

  const rootClasses = classNames(
    styles.root,
    isOpen || styles.closed,
    ((isActive && isOpen && !routeId) || (isActive && !isOpen)) &&
      styles.active,
    className,
  );

  return (
    <>
      <div className={rootClasses} onClick={handleOnClickProject}>
        <div className={styles.imgWrapper}>
          <img
            className={styles.img}
            src={!isOpen ? projectData.image_tn_path : projectData?.image_path}
            alt={"Project"}
          />
          <button className={styles.imageBtn}>
            <img
              className={styles.imageBtnIcon}
              src={MenuIcon}
              alt={"icon: Project Menu"}
            />
          </button>
        </div>

        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h2 className={styles.title}>{projectData.name}</h2>
            {isOpen && (
              <AnalyticList
                className={styles.list}
                analytics={currentAnalytic}
                isViews={false}
                projectId={projectData._id}
              />
            )}
          </div>
          <button
            className={classNames(
              styles.collapseBtn,
              isCollapsed && isOpen && styles.active,
            )}
            onClick={(e) => handleChangeIsCollapsed(e)}
          >
            <img
              className={styles.arrowCollapseBtn}
              src={ArrowIcon}
              alt={"Collapse Arrow"}
            />
          </button>
        </div>
      </div>

      {isOpen && (
        <div
          className={classNames(styles.routeList, isCollapsed || styles.closed)}
        >
          {project && (
            <RouteList data={project} onSwitchSidebar={onSwitchSidebar} />
          )}
          {isProjectLoading && (
            <div className={styles.center}>
              <Loading />
            </div>
          )}
        </div>
      )}
    </>
  );
};
