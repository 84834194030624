import { FC } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import { IClient } from "@/features/client/types";
import MenuIcon from "@/img/icons/menu.svg";
import styles from "./Header.module.scss";

interface IHeaderProps {
  clientData?: IClient | null;
  onSwitchSidebar: () => void;
}

export const Header: FC<IHeaderProps> = ({ clientData, onSwitchSidebar }) => {
  return (
    <header className={styles.root}>
      <button className={styles.btn} onClick={onSwitchSidebar}>
        <img className={styles.menu} src={MenuIcon} alt={"icon: Menu"} />
      </button>
      <Link to={ROUTES.main.createURL(clientData?._id || "")}>
        <img
          className={styles.logo}
          src={clientData?.header_image_path}
          alt={clientData?.name}
        />
      </Link>
    </header>
  );
};
