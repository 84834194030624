import { createContext, FC, ReactNode, useCallback, useState } from "react";
import { LoadingOverlay } from "@/components/LoadingOverlay";

interface ILoadingOverlayProviderProps {
  children: ReactNode;
}

interface ILoadingOverlayContextProps {
  toggleLoadingOverlay: (data: boolean) => void;
}

export const LoadingOverlayContext = createContext<ILoadingOverlayContextProps>(
  {
    toggleLoadingOverlay: () => {},
  },
);

export const LoadingOverlayProvider: FC<ILoadingOverlayProviderProps> = ({
  children,
}) => {
  const [loadingOverlayState, setLoadingOverlayState] = useState<
    boolean | null
  >(null);

  const toggleLoadingOverlay = useCallback((data: boolean) => {
    setLoadingOverlayState(data);
  }, []);

  return (
    <LoadingOverlayContext.Provider value={{ toggleLoadingOverlay }}>
      {children}
      {loadingOverlayState && <LoadingOverlay />}
    </LoadingOverlayContext.Provider>
  );
};
