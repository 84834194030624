import { createContext, FC, ReactNode, useCallback, useState } from "react";
import {
  DetailsDialog,
  DialogContent,
} from "@/components/DetailsDialog/DetailsDialog";
import { useScrollLock } from "@/hooks/useSkrollLock";

interface IDialogProviderProps {
  children: ReactNode;
}

interface IDialogContextProps {
  toggleDialog: () => void;
  setDialogContent: (content: DialogContent | null) => void;
}

export const DialogContext = createContext<IDialogContextProps>({
  toggleDialog: () => {},
  setDialogContent: (content: DialogContent | null) => {},
});

export const DialogProvider: FC<IDialogProviderProps> = ({ children }) => {
  const { enableScroll, disableScroll } = useScrollLock();
  const [dialogShow, setDialogShow] = useState<boolean>(false);
  const [dialogContent, setDialogContent] = useState<DialogContent | null>(
    null,
  );

  const toggleDialog = useCallback(() => {
    setDialogShow((prev) => {
      const isShow = !prev;

      if (isShow && window.innerWidth < 768) {
        disableScroll();
      } else {
        enableScroll();
      }

      return isShow;
    });
  }, [disableScroll, enableScroll]);

  return (
    <DialogContext.Provider value={{ toggleDialog, setDialogContent }}>
      {children}
      {dialogShow && dialogContent && (
        <DetailsDialog onClose={toggleDialog} content={dialogContent} />
      )}
    </DialogContext.Provider>
  );
};
