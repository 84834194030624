import { ROUTES } from "@/routing/constants";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useGetProjectIdByRoute } from "@/hooks/useGetProjectIdByRoute";

const UrlForReplace = "https://myrecord.page.link";
const linkMarkdownRegex = /\[([^\]]+)]\(([^)]+)\)/g;

const createRoutePath = (
  routeId: string,
  clientId?: string,
  projectId?: string,
) => {
  if (clientId && routeId) {
    return ROUTES.main.createURL(clientId, {
      projectId,
      routeId,
      playInRow: true,
    });
  }

  if (clientId) {
    return ROUTES.mainRoute.createURL(clientId, routeId);
  }

  return ROUTES.route.createURL(routeId);
};

const createStoryPath = (storyId: string, clientId?: string) => {
  return clientId
    ? ROUTES.mainStory.createURL(clientId, storyId)
    : ROUTES.story.createURL(storyId);
};

export const useModifyMarkdown = () => {
  const { clientId } = useParams();

  const getProjectId = useGetProjectIdByRoute();

  const modifyMarkdown = useCallback(
    (description?: string | null) => {
      if (!description) {
        return null;
      }

      return description.replace(linkMarkdownRegex, (_, text, url) => {
        if (url.startsWith(UrlForReplace)) {
          const urlObject = new URL(url);
          const routeId = urlObject.searchParams.get("routeId");
          const storyId = urlObject.searchParams.get("storyId");
          const id = routeId || storyId;
          const projectId = getProjectId(routeId || "");

          if (id) {
            const basePath = routeId && projectId ? window.location.origin : "";
            const path = routeId
              ? createRoutePath(id, clientId, projectId)
              : createStoryPath(id, clientId);

            if (path) {
              const newUrl = basePath + path;
              return `[${text}](${newUrl})`;
            }
          }
        }
        return `[${text}](${url})`;
      });
    },
    [clientId, getProjectId],
  );

  return modifyMarkdown;
};
