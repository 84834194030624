import React, { FC, useCallback } from "react";
import classNames from "classnames";
import styles from "./Toggle.module.scss";

interface IToggleProps {
  className?: string;
  name: string;
  value: boolean;
  onClick: () => void;
}

export const Toggle: FC<IToggleProps> = ({
  className = "",
  value,
  name,
  onClick,
}) => {
  const handleChange = useCallback(
    (event: React.ChangeEvent) => {
      event.stopPropagation();
      onClick();
    },
    [onClick],
  );

  return (
    <div className={classNames(styles.root, className)}>
      <p>{name}</p>
      <div className={styles.btn}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={value}
          onChange={(event) => handleChange(event)}
        />
        <div className={styles.knobs}></div>
        <div className={styles.layer}></div>
      </div>
    </div>
  );
};
