import { FC, useMemo, useRef } from "react";
import { TopBar } from "@/components/TopBar";
import { useParams } from "react-router-dom";
import { defaultCoordinates } from "@/features/map/components/Map/constants";
import { Map } from "@/features/map/components/Map";
import { Player } from "@/components/Player";
import { StoryDialog } from "@/features/stories/components/StoryDialog";
import { ErrorCase } from "@/components/ErrorCase";
import { WordKeys } from "@/constants/WordKeys";
import { useStoryPage } from "@/pages/storyPage/useStoryPage";
import classNames from "classnames";
import styles from "./StoryPage.module.scss";

interface IStoryPageProps {
  singlePage?: boolean;
}

export const StoryPage: FC<IStoryPageProps> = ({ singlePage = true }) => {
  const { storyId = "" } = useParams();
  const { data, error } = useStoryPage(storyId);
  const rootRef = useRef<HTMLDivElement>(null);

  const coordinates = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return {
      lat: data?.location?.coordinates?.[0] || defaultCoordinates.lat,
      lng: data?.location?.coordinates?.[1] || defaultCoordinates.lng,
    };
  }, [data]);

  return (
    <div className={classNames(styles.root, singlePage && styles.pd)}>
      <TopBar className={styles.topBar} title={data?.name || ""} />
      <div className={styles.container}>
        {data && (
          <StoryDialog
            className={classNames(styles.content, styles.info)}
            storyData={data}
          />
        )}
        {error && (
          <ErrorCase
            className={styles.content}
            messageKey={WordKeys.errors.storyIdIsNotValid}
          />
        )}
        <div ref={rootRef} className={classNames(styles.content, styles.map)}>
          <Map
            storyId={storyId}
            storyCoordinates={coordinates}
            rootRef={rootRef}
          />
          <Player activeStory={data} />
        </div>
      </div>
    </div>
  );
};
