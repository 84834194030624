import { useCallback, useEffect, useState } from "react";
import { Player } from "@/components/Player/Player";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { IStory } from "@/features/stories/types";
import { useActiveData } from "@/hooks/useActiveData";

interface IActiveStory {
  data: IStory | null;
  index: number | null;
}

export const PlayerWrapper = () => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const [activeStory, setActiveStory] = useState<IActiveStory | null>(null);
  const { route: routeData } = useActiveData();
  const { storyId, playInRow } = parsedSearchParams;

  useEffect(() => {
    if (storyId) {
      routeData?.sortStories?.forEach((story, index) => {
        if (story?._id === storyId) {
          setActiveStory(() => ({ data: story, index }));
        }
      });
    } else {
      setActiveStory(null);
    }
  }, [routeData?.sortStories, storyId]);

  const onPlayNext = useCallback(() => {
    if (!playInRow) {
      return;
    }

    const newIndex = (activeStory?.index || 0) + 1;
    const newStory = routeData?.sortStories?.[newIndex];

    if (newStory) {
      mergeSearchParams({ storyId: newStory?._id, isPlay: true });
    }
  }, [
    playInRow,
    activeStory?.index,
    routeData?.sortStories,
    mergeSearchParams,
  ]);

  return <Player activeStory={activeStory?.data} onEnd={onPlayNext} />;
};
