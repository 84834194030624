import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { handleError } from "@/helpers/handleError";
import { WordKeys } from "@/constants/WordKeys";
import { useGetRouteByIdQuery } from "@/features/routes/routesApi";
import { setRouteData } from "@/store/routeSlice";
import { setAnalyticData } from "@/store/analyticSlice";
import { aggregateAnalytics } from "@/helpers/aggregateAnalytics";

export const useRoute = (routeId: string) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { data, isLoading, error } = useGetRouteByIdQuery(
    { routeId },
    { skip: !routeId },
  );

  useEffect(() => {
    if (data) {
      dispatch(setRouteData(data));
    }
  }, [dispatch, data]);

  useEffect(() => {
    if (data) {
      const list: any[] = data?.sortStories?.map((item) => ({
        id: item?._id,
        parentId: routeId,
        views: item?.plays || 0,
        share: item?.shares || 0,
      }));

      const routeAnalytic = aggregateAnalytics(list)?.[routeId];

      routeAnalytic && list.push(routeAnalytic);

      // dispatch stories and routes (items will be added only when they don't exist)
      dispatch(setAnalyticData(list));
    }
  }, [data, dispatch, routeId]);

  useEffect(() => {
    if (error) {
      handleError(error, t(WordKeys.errors.defaultErrorMessage));
    }
  }, [error, t]);

  return {
    routeData: data,
    isLoading,
    error,
  };
};
