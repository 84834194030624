import { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { MAIN_PAGE_ROOT, ROUTES } from "./constants";
import { NotFound } from "@/components/NotFound";
import { AppLayout } from "@/components/AppLayout";
import { ErrorCase } from "@/components/ErrorCase";
import { MainPage } from "@/pages/main";
import { RoutePage } from "@/pages/routePage";
import { StoryPage } from "@/pages/storyPage";
import { DialogProvider } from "@/context/DetailsDialogContext";

const DEFAULT_PATH = ROUTES.errorPage.createURL();

export const Router: FC = () => (
  <Routes>
    <Route path="/" element={<Navigate to={DEFAULT_PATH} />} />
    <Route
      path={MAIN_PAGE_ROOT}
      element={
        <DialogProvider>
          <AppLayout />
        </DialogProvider>
      }
    >
      <Route path={ROUTES.main.path} element={<MainPage />} />
      <Route
        path={ROUTES.mainRoute.path}
        element={<RoutePage singlePage={false} />}
      />
      <Route
        path={ROUTES.mainStory.path}
        element={<StoryPage singlePage={false} />}
      />
      <Route index path="*" element={<ErrorCase />} />
    </Route>

    <Route
      path={ROUTES.route.path}
      element={
        <DialogProvider>
          <RoutePage />
        </DialogProvider>
      }
    />
    <Route
      path={ROUTES.story.path}
      element={
        <DialogProvider>
          <StoryPage />
        </DialogProvider>
      }
    />

    <Route path={ROUTES.errorPage.path} element={<NotFound />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
);
