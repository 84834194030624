import { FC, useCallback, useEffect, useState } from "react";
import { StoryNav } from "@/features/stories/components/StoryNav";
import { IStory } from "@/features/stories/types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import styles from "./StoryList.module.scss";

interface IStoryListProps {
  storiesData: IStory[];
}

export const StoryList: FC<IStoryListProps> = ({ storiesData }) => {
  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const [collapseId, setCollapseId] = useState<string | undefined>(
    parsedSearchParams?.storyId,
  );

  const handleToggleCollapse = useCallback((id?: string) => {
    setCollapseId(() => id);
  }, []);

  useEffect(() => {
    setCollapseId(parsedSearchParams.storyId);
  }, [parsedSearchParams.storyId]);

  return (
    <div className={styles.root}>
      {storiesData?.map((story, index) => (
        <StoryNav
          key={story._id + index}
          className={styles.story}
          storyData={story}
          collapseIdActive={collapseId}
          onToggleCollapse={handleToggleCollapse}
        />
      ))}
    </div>
  );
};
