import { FC, useEffect, memo, useCallback } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import { IRoute } from "@/features/routes/types";
import { IProject } from "@/features/projects/types";
import { defaultCoordinates } from "./constants";
import { ILocation } from "@/types/location";

interface IMapCenterProps {
  routeId?: string;
  projectData?: IProject | null;
  routeData?: IRoute | null;
  storyData?: google.maps.LatLngLiteral | null;
  userCoordinates?: google.maps.LatLngLiteral | null;
}

export const MapCenter: FC<IMapCenterProps> = memo(
  ({ routeId, projectData, routeData, storyData, userCoordinates }) => {
    const map = useMap();

    const getBounds = useCallback(
      (list: Array<{ location: ILocation }>) => {
        const bounds = new google.maps.LatLngBounds();

        for (let i = 0; i < list?.length; i++) {
          const coordinates = list?.[i]?.location?.coordinates;
          const newCoordinate = {
            lat: coordinates?.[0] || defaultCoordinates.lat,
            lng: coordinates?.[1] || defaultCoordinates.lng,
          };

          bounds?.extend(newCoordinate);
        }

        if (userCoordinates) {
          bounds?.extend(userCoordinates);
        }

        return bounds;
      },
      [userCoordinates],
    );

    useEffect(() => {
      if (routeId && routeData) {
        map?.fitBounds(getBounds(routeData?.sortStories));
      }
      if (projectData && !routeId) {
        map?.fitBounds(getBounds(projectData?.sortRoutes));
      }
      if (storyData) {
        map?.fitBounds(
          getBounds([
            {
              location: {
                coordinates: [storyData.lat, storyData.lng],
              } as any as ILocation,
            },
          ]),
        );
      }
    }, [getBounds, map, projectData, routeData, storyData, routeId]);

    return <></>;
  },
);
