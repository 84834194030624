import { FC, useMemo } from "react";
import { TopBar } from "@/components/TopBar";
import { ProjectMain } from "@/pages/main/components/ProjectMainView";
import { MapView } from "@/features/map/MapView";
import { useActiveData } from "@/hooks/useActiveData";
import { RouteView } from "@/features/routes/RouteView";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { ErrorCase } from "@/components/ErrorCase";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import { useWindowSize } from "@/hooks/useWindowSize";
import { RouteList } from "@/features/routes/components/RouteList";
import { RouteSlider } from "@/features/routes/components/RouteSlider";
import { useMainPage } from "@/pages/main/useMainPage";
import { useProject } from "@/pages/main/useProject";
import { Loading } from "@/components/Loading";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { IGetClientResponseBody } from "@/features/client/types";
import classNames from "classnames";
import styles from "./MainPage.module.scss";

export const MainPage: FC = () => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const { data: clientData, isLoading } = useSelector(
    (state: RootState) => state.clientData,
  );

  const { project, route } = useActiveData();

  const { parsedSearchParams, refreshSearchParams } =
    useSearchParams<ISearchParams>();
  const { projectId, routeId, storyId } = parsedSearchParams;

  const { activeClientProject } = useMainPage({
    clientData: clientData as IGetClientResponseBody,
    refreshSearchParams,
    projectId,
  });

  const { isLoading: isProjectLoading } = useProject(activeClientProject?._id);

  const isProject = !!(projectId && activeClientProject && !routeId);
  const isRoute = useMemo(() => routeId, [routeId]);

  if (!clientData && !isLoading) {
    return <ErrorCase messageKey={WordKeys.errors.clientIdIsNotValid} />;
  }

  return (
    <>
      <Helmet>
        <title>{clientData?.name}</title>
        <link
          id="favicon"
          rel="icon"
          href={clientData?.footer_image_path}
          type="image/x-icon"
        />
      </Helmet>
      <div className={styles.topBarWrapper}>
        <TopBar
          className={styles.topBar}
          title={project?.name || ""}
          subtitle={(routeId && route?.name) || ""}
          projectData={(!isProject || isMobile) ? project : null}
        />
      </div>
      {isMobile && project && routeId && !isProjectLoading && (
        <RouteSlider className={styles.routeSwiper} data={project} />
      )}
      {isProjectLoading && isMobile && (
        <div className={styles.center}>
          <Loading />
        </div>
      )}
      <div className={styles.content}>
        <div
          className={classNames(styles.contentBox, isRoute && styles.routeView)}
        >
          {isProject && !isMobile && <ProjectMain project={activeClientProject} />}
          {isRoute && <RouteView routeId={routeId} />}
          {!routeId && !activeClientProject && !isLoading && (
            <p className={styles.text}>{t(WordKeys.errors.projectsNotExist)}</p>
          )}
        </div>
        <MapView
          className={styles.map}
          projectId={projectId}
          routeId={routeId}
          storyId={storyId}
        />
        {isMobile && project && isProject && !isProjectLoading && (
          <RouteList
            data={project}
            className={styles.routeList}
            elementClassName={styles.elementRouteList}
          />
        )}
        {isProjectLoading && isMobile && isProject && (
          <div className={styles.center}>
            <Loading />
          </div>
        )}
      </div>
    </>
  );
};
