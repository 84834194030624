import {
  ChangeEvent,
  FC,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PLAYER_STEP } from "@/constants/Player";
import {
  PlayerSpeed,
  PlayerSpeedEnum,
} from "@/components/PlayerControl/constants";
import { OnProgressProps } from "react-player/base";
import { ISearchParams } from "@/types/searchParams";
import { useCacheBuster } from "@/hooks/useCacheBuster";
import ReactPlayer from "react-player";
import IconArrow from "@/img/icons/chevron_bottom_blue.svg";
import IconNext from "@/img/icons/playerNext.svg";
import IconBack from "@/img/icons/playerBack.svg";
import classNames from "classnames";
import styles from "./PlayerControl.module.scss";

interface ISound {
  url: string | null;
  playing: boolean;
  playbackRate: PlayerSpeedEnum;
}

interface IPlayerControlProps {
  playerRef: MutableRefObject<ReactPlayer | null>;
  audioUrl: string;
  onProgress: (state: OnProgressProps) => void;
  onEnd: () => void;
  parsedSearchParams: ISearchParams;
  mergeSearchParams: (newParams: Partial<ISearchParams>) => void;
}

export const PlayerControl: FC<IPlayerControlProps> = ({
  playerRef,
  audioUrl,
  onProgress,
  onEnd,
  parsedSearchParams,
  mergeSearchParams,
}) => {
  const { cacheBuster, bustCache } = useCacheBuster();
  const [sound, setSound] = useState<ISound>({
    url: audioUrl,
    playing: false,
    playbackRate: PlayerSpeedEnum.First,
  });

  const isPlayParam = useMemo(
    () => parsedSearchParams?.isPlay,
    [parsedSearchParams],
  );

  const handlePlay = useCallback(() => {
    setSound((prev) => ({ ...prev, playing: true }));
  }, []);

  const handlePause = useCallback(() => {
    setSound((prev) => ({ ...prev, playing: false }));
  }, []);

  const handleSetPlaybackRate = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const rate = parseFloat(event?.target?.value);
      setSound((prev) => ({ ...prev, playbackRate: rate }));
    },
    [],
  );

  const handleSeekChange = useCallback(
    (step: number) => {
      const currentTime = playerRef?.current?.getCurrentTime() || 0;
      playerRef?.current?.seekTo(currentTime + step, "seconds");
    },
    [playerRef],
  );

  const onMoveForward = useCallback(() => {
    handleSeekChange(-PLAYER_STEP);
  }, [handleSeekChange]);

  const onMoveBackward = useCallback(() => {
    handleSeekChange(PLAYER_STEP);
  }, [handleSeekChange]);

  const handleTogglePlaying = useCallback(() => {
    mergeSearchParams({
      isPlay: !isPlayParam ? true : undefined,
    });
  }, [isPlayParam, mergeSearchParams]);

  useEffect(() => {
    setSound((prev) => ({ ...prev, url: audioUrl }));
    bustCache();
  }, [audioUrl, bustCache]);

  useEffect(() => {
    isPlayParam ? handlePlay() : handlePause();
  }, [handlePause, handlePlay, isPlayParam]);

  return (
    <div className={styles.root}>
      <div className={styles.selectWrapper}>
        <select
          className={styles.select}
          onChange={(event) => handleSetPlaybackRate(event)}
        >
          {PlayerSpeed.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        <img
          className={styles.selectArrow}
          src={IconArrow}
          alt={"Select Arrow"}
        />
      </div>
      <div className={styles.control}>
        <button
          className={classNames(styles.btn, styles.back)}
          onClick={onMoveForward}
        >
          <img src={IconNext} alt={"Go +10 seconds"} />
        </button>

        <button
          className={classNames(styles.btn, styles.pause)}
          onClick={handleTogglePlaying}
        >
          {isPlayParam ? (
            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M6 19H10V5H6V19ZM14 5V19H18V5H14Z" />
            </svg>
          ) : (
            <svg className={styles.iconPlay} viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
              <path d="M17.7089 12.5L10 18.2875L10 6.71252L17.7089 12.5Z" />
            </svg>
          )}
        </button>

        <button
          className={classNames(styles.btn, styles.next)}
          onClick={onMoveBackward}
        >
          <img src={IconBack} alt={"Go -10 seconds"} />
        </button>

        <ReactPlayer
          className={styles.player}
          ref={playerRef}
          url={`${sound?.url}?boost=${cacheBuster}` || ""}
          onProgress={onProgress}
          playing={sound?.playing}
          playbackRate={sound?.playbackRate}
          onEnded={onEnd}
        />
      </div>
    </div>
  );
};
