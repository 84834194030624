import React, { FC, useCallback } from "react";
import { IAnalytics } from "@/types/analytics";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import { useCopyText } from "@/hooks/useCopyText";
import { useParams } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import classNames from "classnames";
import styles from "./AnalyticList.module.scss";

interface IAnalyticListProps {
  className?: string;
  countText?: string;
  analytics: IAnalytics;
  routeId?: string;
  projectId?: string;
  isViews?: boolean;
}

export const AnalyticList: FC<IAnalyticListProps> = ({
  className = "",
  countText = WordKeys.analytic.tracks,
  analytics,
  routeId,
  projectId,
  isViews = true,
}) => {
  const { clientId } = useParams();
  const { t } = useTranslation();
  const { copy } = useCopyText();

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      const path =
        (routeId && ROUTES.route.createURL(routeId)) ||
        (projectId &&
          clientId &&
          ROUTES.main.createURL(clientId, { projectId: projectId }));

      if (path) {
        event.stopPropagation();
        const url = window.location.origin + path;
        await copy(url, t(WordKeys.message.copyRouteUrlSuccess));
      }
    },
    [clientId, copy, projectId, routeId, t],
  );

  return (
    <ul className={classNames(styles.root, className)}>
      <li className={styles.item}>
        <p>
          {analytics.tracksCount || ""}
          &nbsp;{t(countText)}
        </p>
      </li>
      {isViews && (
        <li className={styles.item}>
          <p>
            {analytics.views || ""}
            &nbsp;{t(WordKeys.analytic.views)}
          </p>
        </li>
      )}
      <li className={styles.item}>
        <p
          onClick={handleClick}
          style={{ cursor: routeId && "pointer" }}
          title={
            (routeId && t(WordKeys?.analytic?.shareRoute)) ||
            (projectId && t(WordKeys?.analytic?.shareProject))
          }
        >
          {analytics.share || ""}
          &nbsp;{t(WordKeys.analytic.share)}
        </p>
      </li>
    </ul>
  );
};
