import { FC, useCallback, useMemo } from "react";
import { IProjectRoute } from "@/features/projects/types";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { getMarkerIcon } from "@/features/map/components/StoryMarker/getMarkerIcon";
import RouteMarkerIcon from "@/img/map/routeMarker.svg";
import styles from "../StoryMarker/StoryMarker.module.scss";

interface IRouteMarkerProps {
  routeData: IProjectRoute;
  mergeSearchParams: any;
}

export const RouteMarker: FC<IRouteMarkerProps> = ({
  routeData,
  mergeSearchParams,
}) => {
  const coordinates = useMemo(
    () => ({
      lat: routeData.location.coordinates[0],
      lng: routeData.location.coordinates[1],
    }),
    [routeData.location.coordinates],
  );

  const handleClick = useCallback(() => {
    mergeSearchParams({ routeId: routeData?._id });
  }, [mergeSearchParams, routeData?._id]);

  return (
    <AdvancedMarker
      className={styles.root}
      position={coordinates}
      onClick={handleClick}
    >
      {getMarkerIcon({
        className: styles.routeIcon,
        fill: "#ffffff",
      })}
      <div className={styles.content}>
        <img
          className={styles.routeSmallIcon}
          src={RouteMarkerIcon}
          alt={"Route Marker Icon"}
        />
      </div>
    </AdvancedMarker>
  );
};
