import { useGetClientQuery } from "@/features/client/clientApi";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { handleError } from "@/helpers/handleError";
import { WordKeys } from "@/constants/WordKeys";
import { useLoadingOverlay } from "@/hooks/useLoadingOverlay";
import { TFunction } from "i18next";
import { useDispatch } from "react-redux";
import { setClientData, setClientLoading } from "@/store/clientSlice";

export const useInitialData = (t: TFunction) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const { toggleLoadingOverlay } = useLoadingOverlay();
  const { data, error, isLoading } = useGetClientQuery(
    { id: clientId || "" },
    { skip: !clientId },
  );

  useEffect(() => {
    toggleLoadingOverlay(isLoading);
  }, [isLoading, toggleLoadingOverlay]);

  useEffect(() => {
    if (data) {
      dispatch(setClientData(data));
    }
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setClientLoading(isLoading));
  }, [isLoading, dispatch]);

  useEffect(() => {
    if (error) {
      handleError(error, t(WordKeys.errors.defaultErrorMessage));
    }
  }, [t, error]);

  return {
    data,
    isLoading,
  };
};
