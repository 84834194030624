import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { IAnalytics } from "@/types/analytics";

export const useAnalytic = (
  id: string,
  trackCount?: number,
): { currentAnalytic: IAnalytics } => {
  const analyticData = useSelector((state: RootState) => state.analyticData);

  const currentElement = useMemo(() => {
    return analyticData?.data?.find((item) => item.id === id);
  }, [analyticData?.data, id]);

  const analytic: IAnalytics = useMemo(
    () => ({
      share: currentElement?.share || 0,
      views: currentElement?.views || 0,
      tracksCount: trackCount || 0,
    }),
    [currentElement?.share, currentElement?.views, trackCount],
  );

  return {
    currentAnalytic: analytic,
  };
};
