import {
  IClientProject,
  IGetClientResponseBody,
} from "@/features/client/types";
import { getImgUrl } from "@/helpers/getImgUrl";

export const transformClientResponse = (response: IGetClientResponseBody) => {
  response.header_image_path = getImgUrl(response.header_image_path);
  response.footer_image_path = getImgUrl(response.footer_image_path);

  response.projects.forEach((project) => {
    project.image_path = getImgUrl(project.image_path);
    project.image_tn_path = getImgUrl(project.image_tn_path);
  });

  response?.projects?.sort(
    (a: IClientProject, b: IClientProject) => a.topsort - b.topsort,
  );

  return response;
};
