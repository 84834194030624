import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { IStory, IStoryResponse } from "@/features/stories/types";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { PlayerControl } from "@/components/PlayerControl";
import { PlayerStory } from "@/components/PlayerStory";
import { OnProgressProps } from "react-player/base";
import { usePlayer } from "@/components/Player/usePlayer";
import { useDispatch } from "react-redux";
import { setPlayerTime } from "@/store/playerTimeSlice";
import ReactPlayer from "react-player";
import styles from "./Player.module.scss";

interface IPlayerProps {
  activeStory?: IStoryResponse | IStory | null;
  onEnd?: () => void;
}

export const Player: FC<IPlayerProps> = ({ activeStory, onEnd }) => {
  const dispatch = useDispatch();
  const player = useRef<ReactPlayer | null>(null);
  const [progress, setProgress] = useState<number>(0);
  const { postPlay } = usePlayer();

  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  useEffect(() => {
    if (
      !(window?.navigator as any)?.userActivation?.hasBeenActive &&
      parsedSearchParams?.isPlay
    ) {
      mergeSearchParams({ isPlay: undefined });
    }
  }, [parsedSearchParams?.isPlay, mergeSearchParams]);

  const handleSeekSound = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const percentage: number = Number(event?.target?.value);
      player?.current?.seekTo(
        (percentage || 0) * (player?.current?.getDuration() || 1),
        "seconds",
      );

      setProgress(percentage * 100);
    },
    [],
  );

  const handleOnProgress = useCallback(
    (state: OnProgressProps) => {
      const progress =
        (state?.playedSeconds / (player?.current?.getDuration() || 1)) * 100;

      dispatch(setPlayerTime(player?.current?.getCurrentTime() || null));
      setProgress(progress);
    },
    [dispatch],
  );

  const handleEnded = useCallback(() => {
    mergeSearchParams({
      isPlay: undefined,
    });
    setTimeout(() => {
      setProgress(0);
      dispatch(setPlayerTime(null));
      onEnd && onEnd();
    }, 1000);
  }, [dispatch, mergeSearchParams, onEnd]);

  useEffect(() => {
    if (activeStory?._id) {
      postPlay(activeStory?._id);
    }
  }, [activeStory?._id, dispatch, postPlay]);

  return activeStory ? (
    <div className={styles.root}>
      <div className={styles.content}>
        <PlayerStory className={styles.storyInfo} storyData={activeStory} />
        <PlayerControl
          playerRef={player}
          audioUrl={activeStory?.audio_path}
          onProgress={handleOnProgress}
          onEnd={handleEnded}
          parsedSearchParams={parsedSearchParams}
          mergeSearchParams={mergeSearchParams}
        />
      </div>
      <div className={styles.progressbar}>
        <input
          className={styles.progressInput}
          min={0}
          max={0.999999}
          step={0.001}
          type={"range"}
          onChange={handleSeekSound}
        />
        <div
          className={styles.progress}
          style={{ width: `${progress}%` }}
        ></div>
      </div>
    </div>
  ) : (
    <></>
  );
};
