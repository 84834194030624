import { FC, useCallback, useEffect, useState } from "react";
import { useMap } from "@vis.gl/react-google-maps";
import MapLayerIcon from "@/img/map/layer.svg";
import styles from "./MapSwitcher.module.scss";
import classNames from "classnames";

enum MapTypeLayer {
  Roadmap = "roadmap",
  Satellite = "satellite",
}

interface IMapTypeSwitcherProps {
  className?: string;
}

export const MapTypeSwitcher: FC<IMapTypeSwitcherProps> = ({
  className = "",
}) => {
  const map = useMap();
  const [mapType, setMapType] = useState<MapTypeLayer | null>(null);

  useEffect(() => {
    const currentMapType = map?.getMapTypeId() as any as MapTypeLayer;
    setMapType(currentMapType);
  }, [map]);

  useEffect(() => {
    if (mapType) {
      map?.setMapTypeId(mapType);
    }
  }, [map, mapType]);

  const handleClick = useCallback(() => {
    setMapType((prev) => {
      if (prev === MapTypeLayer.Roadmap) {
        return MapTypeLayer.Satellite;
      }

      return MapTypeLayer.Roadmap;
    });
  }, []);

  return (
    <button
      className={classNames(styles.root, className)}
      onClick={handleClick}
    >
      <img className={styles.icon} src={MapLayerIcon} alt="icon: Mat Type Layer" />
    </button>
  );
};
