import { useCallback } from "react";
import { handleError } from "@/helpers/handleError";
import { toast } from "react-toastify";

export const useCopyText = () => {
  const copy = useCallback(async (text: string, successMessage: string) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success(successMessage);
    } catch (e) {
      handleError(e);
    }
  }, []);

  return {
    copy,
  };
};
