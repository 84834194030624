import { createContext, FC, ReactNode, useCallback, useState } from "react";
import {
  DetailsDialog,
  IDetailsDialogContentType,
} from "@/components/DetailsDialog";
import { useScrollLock } from "@/hooks/useSkrollLock";

interface IImageDialogProviderProps {
  children: ReactNode;
}

interface IImageDialogContextProps {
  openImageDialog: (src: string) => void;
}

export const ImageDialogContext = createContext<IImageDialogContextProps>({
  openImageDialog: (src: string) => {},
});

export const ImageDialogProvider: FC<IImageDialogProviderProps> = ({
  children,
}) => {
  const { enableScroll, disableScroll } = useScrollLock();
  const [imageDialogShow, setImageDialogShow] = useState<boolean | null>(null);
  const [srcImage, setSrcImage] = useState<string | null>(null);

  const openImageDialog = useCallback(
    (src: string) => {
      setImageDialogShow(() => {
        setSrcImage(src);
        disableScroll();
        return true;
      });
    },
    [disableScroll],
  );

  const handleClose = useCallback(() => {
    setImageDialogShow(() => {
      enableScroll();
      return false;
    });
  }, [enableScroll]);

  return (
    <ImageDialogContext.Provider value={{ openImageDialog }}>
      {children}
      {imageDialogShow && (
        <DetailsDialog
          onClose={handleClose}
          content={{
            type: IDetailsDialogContentType.ImageDialog,
            data: { src: srcImage || "" },
          }}
        />
      )}
    </ImageDialogContext.Provider>
  );
};
