import { api } from "@/store/api";
import { IStoryRequest, IStoryResponse } from "@/features/stories/types";

const API_PREFIX = "/stories";

const storiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStoryById: builder.query<IStoryResponse, IStoryRequest>({
      query: ({ storyId }) => ({
        url: `${API_PREFIX}/${storyId}`,
      }),
    }),
    reportStoryPlay: builder.mutation<IStoryResponse, IStoryRequest>({
      query: ({ storyId }) => ({
        url: `${API_PREFIX}/${storyId}/played`,
        method: "POST",
      }),
    }),
    reportStoryShared: builder.mutation<IStoryResponse, IStoryRequest>({
      query: ({ storyId }) => ({
        url: `${API_PREFIX}/${storyId}/shared`,
        method: "POST",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetStoryByIdQuery,
  useReportStorySharedMutation,
  useReportStoryPlayMutation,
} = storiesApi;
