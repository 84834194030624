import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import { MarkdownText } from "@/components/MarkdownText";
import classNames from "classnames";
import styles from "./LongDescription.module.scss";

interface ICardDescription {
  className?: string;
  description: string;
  isActive?: boolean;
}

export const LongDescription: FC<ICardDescription> = ({
  className = "",
  description,
  isActive,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isActiveBtn, setIsActiveBtn] = useState(isActive);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpenFullDescription = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    if (isOpen) return;

    if (isActive) {
      if (ref.current?.offsetHeight === ref.current?.scrollHeight) {
        setIsActiveBtn(false);
        return;
      }
    }

    setIsActiveBtn(isActive);
  }, [isActive, isOpen]);

  return (
    <>
      {description ? (
        <div className={className}>
          <div
            ref={ref}
            className={classNames(
              styles.description,
              isActive && styles.longActive,
              isOpen && styles.isOpen,
            )}
          >
            <MarkdownText description={description}></MarkdownText>
          </div>
          {isActiveBtn && (
            <button className={styles.btn} onClick={handleOpenFullDescription}>
              {isOpen
                ? t(WordKeys.routeView.readLess)
                : t(WordKeys.routeView.readMore)}
            </button>
          )}
        </div>
      ) : (
        <div className={className}></div>
      )}
    </>
  );
};
