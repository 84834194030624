import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { api } from "./api";
import { routeSlice } from "@/store/routeSlice";
import { activeProjectSlice } from "@/store/activeProjectSlice";
import { analyticSlice } from "@/store/analyticSlice";
import { clientSlice } from "@/store/clientSlice";
import { playerDataSlice } from "@/store/playerTimeSlice";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    activeProjectData: activeProjectSlice.reducer,
    routeData: routeSlice.reducer,
    clientData: clientSlice.reducer,
    analyticData: analyticSlice.reducer,
    playerData: playerDataSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(api.middleware);
  },
});

const rootReducer = combineReducers({
  activeProjectData: activeProjectSlice.reducer,
  routeData: routeSlice.reducer,
  clientData: clientSlice.reducer,
  analyticData: analyticSlice.reducer,
  playerData: playerDataSlice.reducer,
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
