import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import { MarkdownText } from "@/components/MarkdownText";
import classNames from "classnames";
import styles from "./CardDecription.module.scss";

interface ICardDescription {
  className?: string;
  description: string;
  hideDescription?: boolean;
  onClick: (_: any) => void;
  isActive?: boolean;
}

export const CardDescription: FC<ICardDescription> = ({
  className = "",
  description,
  hideDescription = false,
  onClick,
  isActive,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  return (
    <>
      {description ? (
        <div className={className}>
          {hideDescription || (
            <div
              ref={ref}
              className={classNames(
                styles.description,
                isActive && styles.active,
              )}
            >
              <MarkdownText description={description}></MarkdownText>
            </div>
          )}
          <button className={styles.btn} onClick={onClick}>
            {t(WordKeys.routeView.readMore)}
          </button>
        </div>
      ) : (
        <div className={className}></div>
      )}
    </>
  );
};
