import { FC, useCallback, useEffect, useMemo } from "react";
import { IStory } from "@/features/stories/types";
import { AdvancedMarker, useMap } from "@vis.gl/react-google-maps";
import { getMarkerIcon } from "@/features/map/components/StoryMarker/getMarkerIcon";
import PlayIcon from "@/img/icons/play.svg";
import classNames from "classnames";
import styles from "./StoryMarker.module.scss";

const ActiveMarkerColor = "#266BEC";
const BaseMarkerColor = "#ffffff";

interface IStoryMarkerProps {
  storyData: IStory;
  isActive: boolean;
  isPlaying: boolean;
  mergeSearchParams: any;
}

export const StoryMarker: FC<IStoryMarkerProps> = ({
  storyData,
  isActive,
  isPlaying,
  mergeSearchParams,
}) => {
  const map = useMap();
  const coordinates = useMemo(
    () => ({
      lat: storyData.location.coordinates[0],
      lng: storyData.location.coordinates[1],
    }),
    [storyData.location.coordinates],
  );

  useEffect(() => {
    if (isActive) {
      map?.panTo(coordinates);
    }
  }, [coordinates, isActive, map]);

  const handleClick = useCallback(() => {
    mergeSearchParams({
      storyId: storyData?._id,
      isPlay: !isPlaying || undefined,
    });
  }, [isPlaying, mergeSearchParams, storyData?._id]);

  return (
    <AdvancedMarker
      className={styles.root}
      position={coordinates}
      onClick={handleClick}
    >
      {getMarkerIcon({
        className: styles.icon,
        fill: isActive ? ActiveMarkerColor : BaseMarkerColor,
      })}
      <div
        className={classNames(
          styles.content,
          isActive && styles.active,
          isPlaying && styles.playing,
        )}
      >
        {isActive && !isPlaying && (
          <img
            className={styles.playButton}
            src={PlayIcon}
            alt={"icon: Play"}
          />
        )}

        {isActive && isPlaying && (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        )}

        {!isActive && !isPlaying && (
          <p className={styles.markerOrder}>{storyData?.order}</p>
        )}
      </div>
    </AdvancedMarker>
  );
};
