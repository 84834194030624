import { useEffect, useRef } from "react";

export const useScrollLock = () => {
  const isScrollLocked = useRef(false);
  const previousScrollTop = useRef(0);

  const disableScroll = () => {
    if (!isScrollLocked.current) {
      previousScrollTop.current =
        window.pageYOffset || document.documentElement.scrollTop;
      document.body.style.position = "fixed";
      document.body.style.top = `-${previousScrollTop.current}px`;
      isScrollLocked.current = true;
    }
  };

  const enableScroll = () => {
    if (isScrollLocked.current) {
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, previousScrollTop.current);
      isScrollLocked.current = false;
    }
  };

  useEffect(() => {
    return () => enableScroll();
  }, []);

  return { disableScroll, enableScroll };
};
