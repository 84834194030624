import AppStore from "@/img/icons/appStore.svg";
import GooglePlay from "@/img/icons/googlePlay.svg";
import { PublicLinks } from "@/constants/PublicLinks";
import { IFooterLink } from "@/components/Footer/constants";

export const StoreList: IFooterLink[] = [
  {
    imgSrc: AppStore,
    href: PublicLinks.appStore,
    alt: "icon: App Store",
  },
  {
    imgSrc: GooglePlay,
    href: PublicLinks.googlePlay,
    alt: "icon: Google Play",
  },
];
