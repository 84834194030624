import { useCallback, useMemo } from "react";
import { useSearchParams as useReactRouterSearchParams } from "react-router-dom";
import qs from "qs";

type AnyObject = Record<string, any>;

export const useSearchParams = <TParsedSearchParams extends AnyObject>() => {
  const [searchParams, setSearchParams] = useReactRouterSearchParams();

  const queryString = searchParams.toString();

  const parsedSearchParams = useMemo(() => {
    return qs.parse(queryString) as TParsedSearchParams;
  }, [queryString]);

  const mergeSearchParams = useCallback(
    (newParams: Partial<TParsedSearchParams>, replace = false) => {
      const mergedSearchParams = deepMerge(parsedSearchParams, newParams);
      const newSearchParamsString = qs.stringify(mergedSearchParams);

      setSearchParams(newSearchParamsString, { replace: replace });
    },
    [parsedSearchParams, setSearchParams],
  );

  const refreshSearchParams = useCallback(
    (newParams: Partial<TParsedSearchParams>) => {
      const newSearchParamsString = qs.stringify(newParams);

      setSearchParams(newSearchParamsString);
    },
    [parsedSearchParams, setSearchParams],
  );

  return {
    parsedSearchParams,
    mergeSearchParams,
    refreshSearchParams,
  };
};

const isObject = (item: any): boolean => {
  return item !== null && typeof item === "object" && !Array.isArray(item);
};

const deepMerge = <TData extends AnyObject>(
  objectA: TData,
  objectB: TData,
): TData => {
  const resultObject = { ...objectA };

  for (const keyB in objectB) {
    const valueB = objectB[keyB];

    if (isObject(valueB)) {
      resultObject[keyB] = deepMerge(resultObject[keyB], valueB);
    } else {
      resultObject[keyB] = valueB;
    }
  }

  return resultObject;
};
