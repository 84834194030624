import { FC } from "react";
import { transformTime } from "@/helpers/transformTime";
import { IStory, IStoryResponse } from "@/features/stories/types";
import { Badge } from "@/components/Badge/Badge";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import classNames from "classnames";
import styles from "./PlayerStory.module.scss";

interface IPlayerStory {
  className?: string;
  storyData: IStoryResponse | IStory;
}

export const PlayerStory: FC<IPlayerStory> = ({
  className = "",
  storyData,
}) => {
  const { isMobile } = useWindowSize();
  const { time: playerTime } = useSelector(
    (state: RootState) => state.playerData,
  );

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.imgWrapper}>
        <img
          className={styles.imgStory}
          src={storyData?.image_tn_path}
          alt={"Active Story"}
        />
        <img
          className={styles.imgShadow}
          src={storyData?.image_tn_path}
          alt={"Active Story Shadow"}
        />
      </div>

      <div className={styles.infoText}>
        <div className={styles.infoWrapper}>
          {"order" in storyData && (
            <Badge
              className={styles.badge}
              content={storyData?.order}
              isActive={isMobile}
            />
          )}
          <h6 className={styles.title}>{storyData?.name}</h6>
        </div>
        <div className={styles.subinfo}>
          <p className={styles.time}>
            {transformTime(storyData?.audio_length)} /{" "}
            {transformTime(playerTime || 0)}
          </p>
          <div className={styles.dot}></div>
          <p className={styles.text}>
            {storyData?.author?.first_name} {storyData?.author?.last_name}
          </p>
        </div>
      </div>
    </div>
  );
};
