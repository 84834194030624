import { API_IMG_URL } from "@/constants/common";

export const getImgUrl = (imgUrl: string) => {
  if (!imgUrl) {
    return "/main_icon.png";
  }
  if (!imgUrl.startsWith("http")) {
    return `${API_IMG_URL}/${imgUrl}`;
  }
  return imgUrl;
};
