import { FC } from "react";
import classNames from "classnames";
import styles from "./Badge.module.scss";

interface IBadgeProps {
  className?: string;
  content: number | string;
  isActive?: boolean;
}

export const Badge: FC<IBadgeProps> = ({
  className = "",
  content,
  isActive = false,
}) => {
  return (
    <div
      className={classNames(styles.root, isActive && styles.active, className)}
    >
      <svg
        className={styles.icon}
        viewBox="0 0 26 30"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M12.9999 30C14.8855 26.6549 18.3609 24.5616 21.347 22.1475C24.1914 19.8478 26 16.3957 26 12.5363C26 5.61268 20.1797 0 13 0C5.8203 0 0 5.61268 0 12.5363C0 16.3956 1.80848 19.8477 4.65282 22.1473C7.63892 24.5616 11.1143 26.6549 12.9999 30Z" />
      </svg>
      <span className={styles.content}>{content}</span>
    </div>
  );
};
