import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAnalyticSlice } from "@/types/analytics";

interface IAnalyticState {
  data: null | IAnalyticSlice[];
}

enum UpdatedField {
  Views = "views",
  Share = "share",
}

export const analyticSlice = createSlice({
  name: "new",
  initialState: {
    data: null,
  } as IAnalyticState,
  reducers: {
    setData: (state, action: PayloadAction<null | IAnalyticSlice[]>) => {
      if (state.data === null) {
        state.data = [];
      }
      if (action.payload?.length) {
        if (!state?.data?.length) {
          state.data = [];
        }
        const uniqueIds = new Set(state.data?.map((item) => item.id));

        const uniquePayload = action.payload.filter(
          (payloadItem) => !uniqueIds.has(payloadItem.id),
        );

        if (uniquePayload.length > 0) {
          state.data = [...(state.data || []), ...uniquePayload];
        }
      }
    },
    updatePlays: (
      state,
      action: PayloadAction<null | { id: string; number: number }>,
    ) => {
      if (action.payload && state.data) {
        const { id, number } = action.payload;
        const item = state.data.find((item) => item?.id === id);
        if (item) {
          updateViews(state, item, number - item.views, UpdatedField.Views);
        }
      }
    },
    updateShares: (
      state,
      action: PayloadAction<null | { id: string; number: number }>,
    ) => {
      if (action.payload && state.data) {
        const { id, number } = action.payload;
        const item = state.data?.find((item) => item?.id === id);

        if (item) {
          updateViews(state, item, number - item.share, UpdatedField.Share);
        }
      }
    },
  },
});

const updateViews = (
  state: IAnalyticState,
  item: IAnalyticSlice,
  difference: number,
  field: UpdatedField,
) => {
  item[field] += difference;

  if (item.parentId) {
    let parent = state?.data?.find((item) => item?.id === item?.parentId);

    state.data?.forEach((parentItem) => {
      if (parentItem.id === item.parentId) {
        parent = parentItem;
      }
    });

    if (parent) {
      updateViews(state, parent, difference, field);
    }
  }
};

export const {
  setData: setAnalyticData,
  updateShares,
  updatePlays,
} = analyticSlice.actions;
