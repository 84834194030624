import { useEffect, useState } from "react";
import { MobileSize } from "@/constants/size";

interface IWindowSize {
  width: number;
}

export const useWindowSize = (smallSize: number = MobileSize) => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return { isMobile: windowSize.width <= smallSize };
};
