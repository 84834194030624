import { api } from "@/store/api";
import {
  IGetRouteRequestParams,
  IGetRouteResponseBody,
} from "@/features/routes/types";
import { RouteMapper } from "@/helpers/routeMapper";

const API_PREFIX = "/routes";

const routesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRouteById: builder.query<IGetRouteResponseBody, IGetRouteRequestParams>({
      query: ({ routeId }) => ({
        url: `${API_PREFIX}/${routeId}`,
      }),
      transformResponse(response: IGetRouteResponseBody): any {
        return RouteMapper(response);
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetRouteByIdQuery } = routesApi;
