import Markdown, { ExtraProps } from "react-markdown";
import { Link } from "react-router-dom";
import { AnchorHTMLAttributes, ClassAttributes, FC } from "react";
import { useModifyMarkdown } from "@/hooks/useModifyMarkdown";
import rehypeRaw from "rehype-raw";
import styles from "./MarkdownText.module.scss";

export interface IMarkdownText {
  description: string;
  action?: () => void;
}

const replaceUrl = (
  props: ClassAttributes<HTMLAnchorElement> &
    AnchorHTMLAttributes<HTMLAnchorElement> &
    ExtraProps,
  action?: () => void,
) => {
  return props.href?.startsWith(window.location.origin) ? (
    <Link to={props.href} replace={false} onClick={action}>
      {props.children}
    </Link>
  ) : (
    <a href={props.href}>{props.children}</a>
  );
};

export const MarkdownText: FC<IMarkdownText> = ({ description, action }) => {
  const modifyMarkdown = useModifyMarkdown();

  return (
    <Markdown
      className={styles.root}
      rehypePlugins={[rehypeRaw]}
      components={{ a: (props) => replaceUrl(props, action) }}
    >
      {modifyMarkdown(description)}
    </Markdown>
  );
};
