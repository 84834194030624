import { FC } from "react";
import { useTranslation } from "react-i18next";
import { PublicLinks } from "@/constants/PublicLinks";
import { StoreList } from "@/constants/StoreList";
import { WordKeys } from "@/constants/WordKeys";
import Logo from "@/img/myRecordLogo.svg";
import styles from "./NotFound.module.scss";

export const NotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.root}>
      <a className={styles.logoLink} href={PublicLinks.myRecord}>
        <img className={styles.logo} src={Logo} alt={"logo"} />
      </a>
      <div className={styles.content}>
        <h1 className={styles.title}>{t(WordKeys.notFoundPage.title)}</h1>
        <p className={styles.subtitle}>{t(WordKeys.notFoundPage.subtitle)}</p>
      </div>
      <div>
        <p className={styles.text}>{t(WordKeys.notFoundPage.ourResources)}</p>
        <a
          className={styles.link}
          href={PublicLinks.myRecord}
          target={"_blank"}
          rel="noreferrer"
        >
          {t(WordKeys.notFoundPage.webSiteLinkName)}
        </a>
        <p className={styles.text}>{t(WordKeys.notFoundPage.ourApp)}</p>
        <div className={styles.linksBox}>
          {StoreList.map((item) => (
            <a
              key={item.imgSrc}
              className={styles.linkStore}
              href={item.href}
              target={"_blank"}
              rel="noreferrer"
            >
              <img
                className={styles.imgLink}
                src={item.imgSrc}
                alt={item.alt}
              />
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};
