import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WordKeys } from "@/constants/WordKeys";
import { useTranslation } from "react-i18next";
import { ROUTES } from "@/routing/constants";
import classNames from "classnames";
import styles from "./ErrorCase.module.scss";

const REDIRECT_TIME = 10000;

interface IErrorCaseProps {
  className?: string;
  messageKey?: string;
}

export const ErrorCase: FC<IErrorCaseProps> = ({
  messageKey = WordKeys.errors.defaultErrorMessage,
  className = "",
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(REDIRECT_TIME / 1000);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate(ROUTES.errorPage.createURL());
    }, REDIRECT_TIME);

    const timerInterval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => {
      clearTimeout(redirectTimeout);
      clearInterval(timerInterval);
    };
  }, [navigate]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className={classNames(styles.root, className)}>
      <h2 className={styles.title}>{t(messageKey)}</h2>
      <div>
        <p className={styles.text}>
          {t(WordKeys.errors.redirectingIn)}{" "}
          <span
            className={styles.textAccent}
          >{`${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`}</span>
        </p>
      </div>
    </div>
  );
};
