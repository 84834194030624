import { FC, useEffect, useRef } from "react";
import { Loading } from "@/components/Loading";
import { RouteActive } from "@/features/routes/components/RouteActive";
import { StoryList } from "@/features/stories/components/StoryList";
import { WordKeys } from "@/constants/WordKeys";
import { useTranslation } from "react-i18next";
import { ErrorCase } from "@/components/ErrorCase";
import { useRoute } from "@/features/routes/useRoute";
import { useWindowSize } from "@/hooks/useWindowSize";
import classNames from "classnames";
import styles from "./RouteView.module.scss";

interface IRouteViewProps {
  className?: string;
  routeId?: string;
  redirectWhenError?: boolean;
}

export const RouteView: FC<IRouteViewProps> = ({
  className = "",
  routeId = "",
  redirectWhenError,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  const { routeData, isLoading, error } = useRoute(routeId);

  useEffect(() => {
    if (!isMobile && ref.current && routeId) {
      ref.current.scrollTo(0, 0);
    }
  }, [isMobile, routeId]);

  if (!routeId) {
    return <ErrorCase messageKey={WordKeys.errors.routeIdIsNotValid} />;
  }

  return (
    <div className={classNames(styles.root, className)} ref={ref}>
      {isLoading && (
        <div className={styles.boxCenter}>
          <Loading />
        </div>
      )}
      {redirectWhenError && error && (
        <ErrorCase messageKey={WordKeys.errors.routeIdIsNotValid} />
      )}
      {!redirectWhenError && error && (
        <p className={styles.text}>{t(WordKeys.errors.routeIdIsNotValid)}</p>
      )}
      {routeData && (
        <>
          {isMobile || (
            <RouteActive className={styles.activeRoute} routeData={routeData} />
          )}
          <StoryList storiesData={routeData.sortStories} />
        </>
      )}
    </div>
  );
};
