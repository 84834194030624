import { FC } from "react";
import { AnalyticList } from "@/components/AnalyticList";
import { IProject } from "@/features/projects/types";
import { useAnalytic } from "@/hooks/useAnalytic";
import { ChildrenPositionEnum } from "@/types/position";
import { IClientProject } from "@/features/client/types";
import { MarkdownText } from "@/components/MarkdownText";
import classNames from "classnames";
import styles from "./ProjectDescriptiom.module.scss";

interface IProjectDescriptionProps {
  className?: string;
  children?: JSX.Element;
  onClose?: () => void;
  childrenPosition?: ChildrenPositionEnum;
  projectData: IProject | IClientProject;
}

export const ProjectDescription: FC<IProjectDescriptionProps> = ({
  className,
  projectData,
  children = "",
  childrenPosition,
  onClose,
}) => {
  const { currentAnalytic } = useAnalytic(
    projectData?._id,
    projectData?.routes?.length,
  );

  return (
    <div className={classNames(styles.content, className)}>
      {childrenPosition === ChildrenPositionEnum.Top && children}
      <AnalyticList
        className={styles.analytic}
        analytics={currentAnalytic}
        isViews={false}
        projectId={projectData._id}
      />
      <div className={styles.description}>
        <MarkdownText
          description={projectData.description}
          action={onClose}
        ></MarkdownText>
      </div>
      {childrenPosition === ChildrenPositionEnum.Bottom && children}
    </div>
  );
};
