export const defaultCoordinates = {
  lat: 31.0461,
  lng: 34.8516,
};

export const MapApiConfig = {
  language: "he",
};

export const MapDefaultZoom = 14;

export const MapConfig = {
  mapId: "map",
  defaultZoom: MapDefaultZoom,
  disableDefaultUI: true,
  mapTypeControl: false,
  maxZoom: 18,
};
