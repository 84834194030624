import { FC } from "react";
import PlayIcon from "@/img/icons/play.svg";
import classNames from "classnames";
import styles from "./PlayButton.module.scss";

interface IPlayButtonProps {
  className?: string;
  name: string;
  onClick: (_: any) => void;
  isActive?: boolean;
  isPlay?: boolean;
}

export const PlayButton: FC<IPlayButtonProps> = ({
  className = "",
  name,
  onClick,
  isActive,
  isPlay,
}) => {
  return (
    <button
      className={classNames(
        styles.root,
        isActive && styles.active,
        isPlay && styles.playing,
        className,
      )}
      onClick={onClick}
    >
      <span className={styles.name}>{name}</span>
      <div className={styles.play}>
        {isPlay ? (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        ) : (
          <img className={styles.img} src={PlayIcon} alt={"icon: Play"} />
        )}
      </div>
    </button>
  );
};
