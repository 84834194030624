import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRoute } from "@/features/routes/types";

interface IProjectsState {
  data: null | IRoute;
}

export const routeSlice = createSlice({
  name: "route",
  initialState: {
    data: null,
  } as IProjectsState,
  reducers: {
    setData: (state, action: PayloadAction<null | IRoute>) => {
      state.data = action.payload;
    },
  },
});

export const { setData: setRouteData } = routeSlice.actions;
