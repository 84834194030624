import { FC } from "react";
import { IProject } from "@/features/projects/types";
import { ProjectDescription } from "@/features/projects/components/ProjectDescription";
import { ChildrenPositionEnum } from "@/types/position";
import { IClientProject } from "@/features/client/types";
import classNames from "classnames";
import styles from "./ProjectView.module.scss";

interface IProjectViewProps {
  className?: string;
  onClose?: () => void;
  projectData: IProject | IClientProject;
}

export const ProjectView: FC<IProjectViewProps> = ({
  className = "",
  onClose,
  projectData,
}) => {
  return (
    <div className={classNames(styles.root, className)}>
      <img
        className={styles.img}
        src={projectData.image_path}
        alt={projectData.name}
      />
      <ProjectDescription
        projectData={projectData}
        childrenPosition={ChildrenPositionEnum.Top}
        onClose={onClose}
      >
        <h3 className={styles.title}>{projectData?.name}</h3>
      </ProjectDescription>
    </div>
  );
};
