import { FC, RefObject, useCallback, useEffect, useState } from "react";
import { useWindowSize } from "@/hooks/useWindowSize";
import FullScreenOnIcon from "@/img/map/full-screen-on-icon.svg";
import FullScreenOffIcon from "@/img/map/full-screen-off-icon.svg";
import styles from "./MapSwitcher.module.scss";
import classNames from "classnames";

interface IMapTypeSwitcherProps {
  className?: string;
  rootRef: RefObject<HTMLDivElement>;
}

export const MapSizeSwitcher: FC<IMapTypeSwitcherProps> = ({
  className = "",
  rootRef,
}) => {
  const { isMobile } = useWindowSize(576);
  const [isFullSize, setIsFullSize] = useState(false);

  const handleSetSize = useCallback(() => {
    setIsFullSize((prev) => !prev);
  }, []);

  useEffect(() => {
    isFullSize
      ? rootRef.current?.classList.add(styles.mapLg)
      : rootRef.current?.classList.remove(styles.mapLg);
  }, [isFullSize, rootRef]);

  return isMobile ? (
    <button
      className={classNames(styles.root, styles.pd, className)}
      onClick={handleSetSize}
    >
      <img
        className={styles.icon}
        src={isFullSize ? FullScreenOffIcon : FullScreenOnIcon}
        alt="icon: Mat Type Layer"
      />
    </button>
  ) : (
    <></>
  );
};
