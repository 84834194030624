import { useEffect, useMemo } from "react";
import { IGetClientResponseBody } from "@/features/client/types";

interface IMainPageProps {
  refreshSearchParams: (_: any) => void;
  clientData?: IGetClientResponseBody;
  projectId?: string | null;
  skipRefreshParams?: boolean;
}

export const useMainPage = ({
  clientData,
  refreshSearchParams,
  projectId,
  skipRefreshParams = false,
}: IMainPageProps) => {
  const activeClientProject = useMemo(() => {
    const projectList = clientData?.projects || [];
    const project = projectList.find((item) => item._id === projectId);
    return project || projectList[0];
  }, [clientData?.projects, projectId]);

  useEffect(() => {
    if (!projectId && activeClientProject && !skipRefreshParams) {
      refreshSearchParams({ projectId: activeClientProject?._id });
    }
  }, [
    activeClientProject,
    activeClientProject?._id,
    clientData?.projects,
    projectId,
    refreshSearchParams,
    skipRefreshParams,
  ]);

  return {
    activeClientProject,
  };
};
