import { FC, useCallback } from "react";
import { RouteDialog } from "@/features/routes/components/RouteDialog";
import { StoryDialog } from "@/features/stories/components/StoryDialog";
import { IRoute } from "@/features/routes/types";
import { IStory } from "@/features/stories/types";
import { IProject } from "@/features/projects/types";
import { ProjectView } from "@/features/projects/ProjectView";
import { IClientProject } from "@/features/client/types";
import CloseIcon from "@/img/icons/close.svg";
import classNames from "classnames";
import styles from "./DetailsDialog.module.scss";

export enum IDetailsDialogContentType {
  ProjectDetails = "projectDetails",
  RouteDetails = "routeDetails",
  StoryDetails = "storyDetails",
  ImageDialog = "imageDialog",
}

interface IProjectDialog {
  type: IDetailsDialogContentType.ProjectDetails;
  data: IProject | IClientProject;
}

interface IRouteDialog {
  type: IDetailsDialogContentType.RouteDetails;
  data: IRoute;
}

interface IStoryDialog {
  type: IDetailsDialogContentType.StoryDetails;
  data: IStory;
}

interface IImageDialog {
  type: IDetailsDialogContentType.ImageDialog;
  data: { src: string };
}

export type DialogContent =
  | IProjectDialog
  | IRouteDialog
  | IStoryDialog
  | IImageDialog;

interface IDetailsDialogProps {
  content: DialogContent;
  onClose: () => void;
}

export const DetailsDialog: FC<IDetailsDialogProps> = ({
  onClose,
  content,
}) => {
  const onClickContent = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    },
    [],
  );

  return (
    <div
      className={classNames(
        styles.root,
        content.type === IDetailsDialogContentType.ImageDialog &&
          styles.imgDialog,
      )}
      onClick={onClose}
    >
      <div className={styles.content} onClick={onClickContent}>
        <button className={styles.btn} onClick={onClose}>
          <img className={styles.btnImg} src={CloseIcon} alt={"icon: Close"} />
        </button>
        {content.type === IDetailsDialogContentType.ProjectDetails && (
          <ProjectView
            className={styles.view}
            projectData={content.data}
            onClose={onClose}
          />
        )}
        {content.type === IDetailsDialogContentType.RouteDetails && (
          <RouteDialog
            className={styles.view}
            routeData={content.data}
            isPlayBtn={true}
            onClose={onClose}
          />
        )}
        {content.type === IDetailsDialogContentType.StoryDetails && (
          <StoryDialog
            className={styles.view}
            storyData={content.data}
            isPlayBtn={true}
            onClose={onClose}
          />
        )}
        {content.type === IDetailsDialogContentType.ImageDialog && (
          <img
            className={styles.view}
            src={content.data?.src}
            alt={"Dialog Details"}
          />
        )}
      </div>
    </div>
  );
};
