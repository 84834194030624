import { FC, useRef } from "react";
import { Map } from "@/features/map/components/Map";
import { PlayerWrapper } from "@/components/Player/PlayerWrapper";
import styles from "./MapView.module.scss";
import classNames from "classnames";

interface IMapViewProps {
  className?: string;
  projectId?: string;
  routeId?: string;
  storyId?: string;
  storyCoordinates?: google.maps.LatLngLiteral;
}

export const MapView: FC<IMapViewProps> = ({ className = "", ...props }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  return (
    <div ref={rootRef} className={classNames(styles.root, className)}>
      <Map {...props} rootRef={rootRef} />
      <PlayerWrapper />
    </div>
  );
};
