import React, { FC, useCallback } from "react";
import { IRoute } from "@/features/routes/types";
import { AnalyticList } from "@/components/AnalyticList";
import { IDetailsDialogContentType } from "@/components/DetailsDialog";
import { CardDescription } from "@/components/CardDescription";
import { WordKeys } from "@/constants/WordKeys";
import { useAnalytic } from "@/hooks/useAnalytic";
import { useDialog } from "@/hooks/useDialog";
import { IProjectRoute } from "@/features/projects/types";
import { useWindowSize } from "@/hooks/useWindowSize";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { useTranslation } from "react-i18next";
import { Toggle } from "@/components/Toggle";
import classNames from "classnames";
import styles from "./RouteActive.module.scss";

interface IRouteActiveProps {
  className?: string;
  routeData?: IRoute | IProjectRoute;
  isActive?: boolean;
}

export const RouteActive: FC<IRouteActiveProps> = ({
  className = "",
  routeData,
  isActive = true,
}) => {
  const { t } = useTranslation();
  const { parsedSearchParams, refreshSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const { isMobile } = useWindowSize();
  const { currentAnalytic } = useAnalytic(
    routeData?._id || "",
    routeData?.stories?.length,
  );
  const { dialogContext } = useDialog();
  const { projectId, routeId, playInRow } = parsedSearchParams;

  const handleOnClick = useCallback(() => {
    if (!routeId || routeId === routeData?._id.toString()) {
      return;
    }

    refreshSearchParams({
      projectId: projectId,
      routeId: routeData?._id.toString(),
      playInRow: true,
    });
  }, [projectId, routeId, refreshSearchParams, routeData?._id]);

  const handleOpenDialog = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      dialogContext.setDialogContent({
        type: IDetailsDialogContentType.RouteDetails,
        data: routeData as IRoute,
      });

      dialogContext.toggleDialog();
    },
    [dialogContext, routeData],
  );

  const handleTogglePlayInRow = useCallback(() => {
    mergeSearchParams({
      playInRow: !playInRow ? true : undefined,
    });
  }, [mergeSearchParams, playInRow]);

  return (
    <div
      className={classNames(styles.root, isActive && styles.active, className)}
    >
      <img
        className={styles.img}
        src={routeData?.image_tn_path}
        alt={"Active route"}
      />
      <div className={styles.content} onClick={handleOnClick}>
        <h4 className={styles.title}>{routeData?.name}</h4>
        <CardDescription
          className={styles.description}
          description={routeData?.description || ""}
          hideDescription={isMobile}
          onClick={(event: React.MouseEvent) => handleOpenDialog(event)}
          isActive={true}
        />
        <AnalyticList
          className={styles.analytic}
          analytics={currentAnalytic}
          countText={WordKeys.analytic.routesCount}
          routeId={routeData?._id}
        />
        {(!routeId || routeData?._id === routeId) && (
          <Toggle
            className={styles.btn}
            name={t(WordKeys.routeView.playInRow)}
            onClick={handleTogglePlayInRow}
            value={!!playInRow}
          />
        )}
      </div>
    </div>
  );
};
