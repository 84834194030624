import { useReportStoryPlayMutation } from "@/features/stories/storyApi";
import { useCallback, useEffect } from "react";
import { handleError } from "@/helpers/handleError";
import { useDispatch } from "react-redux";
import { updatePlays } from "@/store/analyticSlice";

export const usePlayer = () => {
  const dispatch = useDispatch();
  const [postPlayStory, { error }] = useReportStoryPlayMutation();
  const postPlay = useCallback(
    async (storyId: string) => {
      const newStory = await postPlayStory({ storyId }).unwrap();
      dispatch(updatePlays({ id: storyId, number: newStory?.plays }));
    },
    [dispatch, postPlayStory],
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return {
    postPlay,
  };
};
