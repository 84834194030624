import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

export const useGetProjectIdByRoute = () => {
  const { data: clientData } = useSelector(
    (state: RootState) => state?.clientData,
  );

  const getProjectId = (routeId: string) => {
    if (!clientData || !clientData?.projects) {
      return undefined;
    }

    const project = clientData?.projects?.find((proj) => {
      return proj?.routes?.some((route) => route?.id === routeId);
    });

    return project ? project?._id : undefined;
  };

  return getProjectId;
};
