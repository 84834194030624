import { FC } from "react";
import { RouteNav } from "@/features/routes/components/RouteNav";
import { WordKeys } from "@/constants/WordKeys";
import { IProject } from "@/features/projects/types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import styles from "./RouteList.module.scss";

interface IRouteList {
  className?: string;
  elementClassName?: string;
  data: IProject;
  onSwitchSidebar?: () => void;
}

export const RouteList: FC<IRouteList> = ({
  data,
  className = "",
  elementClassName = "",
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      {data?.sortRoutes?.map((route, index) => (
        <RouteNav
          key={route?._id + index}
          className={classNames(styles.routeNav, elementClassName)}
          routeData={route}
          {...props}
        />
      ))}
      {!data?.sortRoutes && (
        <p className={styles.container}>{t(WordKeys.errors.routesNotFound)}</p>
      )}
    </div>
  );
};
