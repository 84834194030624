import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";

export const useActiveData = () => {
  const routeData = useSelector((state: RootState) => state.routeData);
  const projectsData = useSelector(
    (state: RootState) => state.activeProjectData,
  );

  const { parsedSearchParams } = useSearchParams<ISearchParams>();
  const { projectId } = parsedSearchParams;

  const project = useMemo(() => {
    if (projectsData?.data) {
      return projectId
        ? projectsData?.data?.find((project) => project._id === projectId)
        : null;
    }
    return null;
  }, [projectId, projectsData]);

  return {
    project,
    route: routeData?.data,
    isProjectLoading: projectsData?.isLoading,
  };
};
