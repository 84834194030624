import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IPlayerTime {
  time: number | null;
}

export const playerDataSlice = createSlice({
  name: "playerTime",
  initialState: {
    time: null,
  } as IPlayerTime,
  reducers: {
    setTime: (state, action: PayloadAction<null | number>) => {
      if (state.time !== action.payload) {
        state.time = action?.payload;
      }
    },
  },
});

export const { setTime: setPlayerTime } = playerDataSlice.actions;
