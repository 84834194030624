import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClient } from "@/features/client/types";

interface IClientState {
  data: null | IClient;
  isLoading: boolean;
}

export const clientSlice = createSlice({
  name: "clientSlice",
  initialState: {
    data: null,
    isLoading: false,
  } as IClientState,
  reducers: {
    setData: (state, action: PayloadAction<null | IClient>) => {
      if (action.payload) {
        state.data = action?.payload;
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action?.payload;
    },
  },
});

export const { setData: setClientData, setLoading: setClientLoading } =
  clientSlice.actions;
