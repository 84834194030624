import { FC, MutableRefObject, RefObject, useEffect, useRef } from "react";
import { APIProvider, Map as GGlMap } from "@vis.gl/react-google-maps";
import { GOOGLE_MAP_API_KEY } from "@/constants/common";
import { ISearchParams } from "@/types/searchParams";
import { useActiveData } from "@/hooks/useActiveData";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useUserCoordinates } from "@/hooks/useUserCoordinates";
import { RouteMarker } from "@/features/map/components/RouteMarker";
import { StoryMarker } from "@/features/map/components/StoryMarker";
import { UserMarker } from "@/features/map/components/UserMarker";
import { SingleStoryMarker } from "@/features/map/components/SingleStoryMarker";
import { defaultCoordinates, MapApiConfig, MapConfig } from "./constants";
import { MapCenter } from "@/features/map/components/Map/MapCenter";
import {
  MapSizeSwitcher,
  MapTypeSwitcher,
} from "@/features/map/components/MapSwitcher";
import classNames from "classnames";
import styles from "./Map.module.scss";

interface IMapProps {
  className?: string;
  projectId?: string;
  routeId?: string;
  storyId?: string;
  storyCoordinates?: google.maps.LatLngLiteral;
  rootRef: RefObject<HTMLDivElement>;
}

export const Map: FC<IMapProps> = ({
  className = "",
  projectId,
  routeId,
  storyId,
  storyCoordinates,
  rootRef,
}) => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const { route: routeData, project: projectData } = useActiveData();
  const userCoordinates = useUserCoordinates();
  const initialUserCoordinates: MutableRefObject<google.maps.LatLngLiteral | null> =
    useRef(null);

  useEffect(() => {
    if (!initialUserCoordinates.current && userCoordinates) {
      initialUserCoordinates.current = userCoordinates;
    }
  }, [userCoordinates]);

  return (
    <APIProvider apiKey={GOOGLE_MAP_API_KEY || ""} {...MapApiConfig}>
      <GGlMap
        className={classNames(
          styles.root,
          parsedSearchParams?.storyId && styles.radTop,
          className,
        )}
        defaultCenter={defaultCoordinates}
        {...MapConfig}
      >
        {routeId &&
          routeData?.sortStories?.map((story, index) => (
            <StoryMarker
              key={story?._id + index}
              storyData={story}
              isActive={story?._id === storyId}
              isPlaying={story?._id === storyId && !!parsedSearchParams?.isPlay}
              mergeSearchParams={mergeSearchParams}
            />
          ))}

        {projectId &&
          !routeId &&
          projectData?.sortRoutes?.map((route) => (
            <RouteMarker
              key={route?._id}
              routeData={route}
              mergeSearchParams={mergeSearchParams}
            />
          ))}

        {!projectId && !routeId && storyId && storyCoordinates && (
          <SingleStoryMarker
            coordinate={storyCoordinates}
            isPlaying={parsedSearchParams?.isPlay}
            mergeSearchParams={mergeSearchParams}
          />
        )}

        {userCoordinates && <UserMarker coordinate={userCoordinates} />}

        <MapCenter
          routeId={routeId}
          projectData={projectData}
          routeData={routeData}
          storyData={storyCoordinates}
          userCoordinates={initialUserCoordinates.current}
        />

        <MapTypeSwitcher className={styles.layerSwitcher} />
        <MapSizeSwitcher className={styles.sizeSwitcher} rootRef={rootRef} />
      </GGlMap>
    </APIProvider>
  );
};
