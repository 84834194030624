import React, { FC, useCallback, useEffect } from "react";
import { IAnalytics } from "@/types/analytics";
import { WordKeys } from "@/constants/WordKeys";
import { useTranslation } from "react-i18next";
import { useCopyText } from "@/hooks/useCopyText";
import { ROUTES } from "@/routing/constants";
import { useReportStorySharedMutation } from "@/features/stories/storyApi";
import { handleError } from "@/helpers/handleError";
import { useDispatch } from "react-redux";
import { updateShares } from "@/store/analyticSlice";
import VisibilityIcon from "@/img/icons/visibility.svg";
import ShareIcon from "@/img/icons/share.svg";
import classNames from "classnames";
import styles from "./StoryAnalytic.module.scss";

interface IStoryAnalyticProps extends Omit<IAnalytics, "tracksCount"> {
  className?: string;
  storyId: string;
}

export const StoryAnalytic: FC<IStoryAnalyticProps> = ({
  className = "",
  storyId,
  share,
  views,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { copy } = useCopyText();
  const [postShareStory, { error }] = useReportStorySharedMutation();

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      event.stopPropagation();
      const url = window.location.origin + ROUTES.story.createURL(storyId);
      await copy(url, t(WordKeys.message.copyStoryUrlSuccess));
      const newStoryData = await postShareStory({ storyId }).unwrap();
      dispatch(updateShares({ id: storyId, number: newStoryData?.shares }));
    },
    [copy, dispatch, postShareStory, storyId, t],
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <ul className={classNames(styles.root, className)}>
      <li className={styles.item}>
        <button
          className={styles.button}
          title={t(WordKeys.analytic.shareStory)}
          onClick={handleClick}
        >
          <img className={styles.icon} src={ShareIcon} alt={"analytics icon"} />
          <span className={styles.text}>{share}</span>
        </button>
      </li>
      <li className={styles.item}>
        <img
          className={styles.icon}
          src={VisibilityIcon}
          alt={"analytics icon"}
        />
        <span className={styles.text}>{views}</span>
      </li>
    </ul>
  );
};
