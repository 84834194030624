import FacebookIcon from "@/img/icons/facebook.svg";
import InstagramIcon from "@/img/icons/instagram.svg";
import TelegramIcon from "@/img/icons/telegram.svg";
import MyRecordLogo from "@/img/myRecordLogo.svg";
import AppStore from "@/img/icons/appStore.svg";
import GooglePlay from "@/img/icons/googlePlay.svg";
import { IClient } from "@/features/client/types";

enum FieldNameLinksURL {
  FB = "fb_url",
  Instagram = "instagram_url",
  Telegram = "telegram_url",
  GoogleStore = "google_store_url",
  AppleStore = "apple_store_url",
  MyRecordUrl = "my_record_url",
}

export interface IFooterLinkConfig {
  imgSrc: string;
  hrefFieldName: FieldNameLinksURL;
  alt: string;
}

export interface IFooterLink extends Omit<IFooterLinkConfig, "hrefFieldName"> {
  href?: string;
}

export const SocialList: IFooterLinkConfig[] = [
  {
    hrefFieldName: FieldNameLinksURL.Telegram,
    imgSrc: TelegramIcon,
    alt: "icon: telegram",
  },
  {
    hrefFieldName: FieldNameLinksURL.Instagram,
    imgSrc: InstagramIcon,
    alt: "icon: instagram",
  },
  {
    hrefFieldName: FieldNameLinksURL.FB,
    imgSrc: FacebookIcon,
    alt: "icon: facebook",
  },
];

export const LogoList: IFooterLinkConfig[] = [
  {
    imgSrc: MyRecordLogo,
    hrefFieldName: FieldNameLinksURL.MyRecordUrl,
    alt: "logo: My Record",
  },
];

export const StoreList: IFooterLinkConfig[] = [
  {
    imgSrc: AppStore,
    hrefFieldName: FieldNameLinksURL.AppleStore,
    alt: "icon: App Store",
  },
  {
    imgSrc: GooglePlay,
    hrefFieldName: FieldNameLinksURL.GoogleStore,
    alt: "icon: Google Play",
  },
];

export const getLinks = (
  urlConfig: IFooterLinkConfig[],
  clientData?: IClient | null,
): IFooterLink[] => {
  const links: IFooterLink[] = [];

  urlConfig.forEach((item) => {
    const hrefFieldName = item.hrefFieldName;

    const footerLink: IFooterLink = {
      imgSrc: item.imgSrc,
      alt: item.alt,
      href: clientData?.[hrefFieldName as keyof IClient] as string,
    };

    links.push(footerLink);
  });

  return links;
};
