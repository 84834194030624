import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { WordKeys } from "@/constants/WordKeys";
import { IProject } from "@/features/projects/types";
import { useDialog } from "@/hooks/useDialog";
import { IDetailsDialogContentType } from "@/components/DetailsDialog";
import classNames from "classnames";
import styles from "./TopBar.module.scss";

interface ITopBarProps {
  className?: string;
  subtitle?: string;
  projectData?: IProject | null;
  title: string;
}

export const TopBar: FC<ITopBarProps> = ({
  className = "",
  subtitle,
  projectData,
  title,
}) => {
  const { t } = useTranslation();
  const { dialogContext } = useDialog();

  const handleOpenDialog = useCallback(() => {
    if (!projectData) {
      return;
    }
    dialogContext.setDialogContent({
      type: IDetailsDialogContentType.ProjectDetails,
      data: projectData,
    });

    dialogContext.toggleDialog();
  }, [dialogContext, projectData]);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.content}>
        <h2 className={styles.title}>{title}</h2>
        {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
      </div>
      {projectData && (
        <button className={styles.btn} onClick={handleOpenDialog}>
          {t(WordKeys.topBar.aboutProject)}
        </button>
      )}
    </div>
  );
};
