import { FC } from "react";
import { Loading } from "@/components/Loading";
import classNames from "classnames";
import styles from "./LoadingOverlay.module.scss";

export const LoadingOverlay: FC = () => {
  return (
    <div className={classNames(styles.root)}>
      <Loading />
    </div>
  );
};
