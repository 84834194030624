import { IRoute } from "@/features/routes/types";
import { IRouteStory } from "@/features/projects/types";
import { IStory, IStoryResponse } from "@/features/stories/types";
import { getImgUrl } from "@/helpers/getImgUrl";

const sortStories = (
  storiesOrder?: IRouteStory[],
  storiesInfo?: IStoryResponse[],
): IStory[] | IStoryResponse[] | undefined => {
  if (!storiesOrder || !storiesInfo) {
    return storiesInfo;
  }

  const storyInfoById: Record<string, IStoryResponse> = {};
  const stories: IStory[] = [];

  storiesInfo.forEach((storyInfo) => {
    storyInfoById[storyInfo._id] = storyInfo;
  });

  storiesOrder?.sort((a: IRouteStory, b: IRouteStory) => a.order - b.order);

  storiesOrder?.forEach((storyOrder) => {
    const story: IStoryResponse | undefined = storyInfoById[storyOrder.id];
    const isDuplicate = stories.find((item) => item._id === storyOrder?.id);

    if (story && !isDuplicate) {
      const newStory: IStory = {
        ...story,
        order: stories?.length + 1,
        image_path: getImgUrl(story.image_path),
        image_tn_path: getImgUrl(story.image_tn_path),
      };
      stories.push(newStory);
    }
  });

  return stories;
};

export const RouteMapper = (response: IRoute) => {
  return {
    ...response,
    sortStories: sortStories(response.stories, response.stories_info),
  };
};
