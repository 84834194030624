export class WordKeys {
  static copyright = "copyright";

  static sidebar = {
    projects: "sidebar.projects",
  };

  static analytic = {
    share: "analytic.share",
    views: "analytic.views",
    tracks: "analytic.tracks",
    routesCount: "analytic.routesCount",
    shareStory: "analytic.shareStory",
    shareRoute: "analytic.shareRoute",
    shareProject: "analytic.shareProject",
  };

  static topBar = {
    aboutProject: "topBar.aboutProject",
  };

  static routeView = {
    readMore: "routeView.readMore",
    readLess: "routeView.readLess",
    playAll: "routeView.playAll",
    playInRow: "routeView.playInRow",
  };

  static btn = {
    pause: "btn.pause",
  };

  static message = {
    copyStoryUrlSuccess: "message.copyStoryUrlSuccess",
    copyRouteUrlSuccess: "message.copyRouteUrlSuccess",
  };

  static notFoundPage = {
    title: "notFoundPage.title",
    subtitle: "notFoundPage.subtitle",
    ourResources: "notFoundPage.ourResources",
    ourApp: "notFoundPage.ourApp",
    webSiteLinkName: "notFoundPage.webSiteLinkName",
  };

  static errors = {
    defaultErrorMessage: "errors.defaultErrorMessage",
    projectsNotFound: "errors.projectsNotFound",
    projectsNotExist: "errors.projectsNotExist",
    routesNotFound: "errors.routesNotFound",
    routeNotFound: "errors.routeNotFound",
    clientIdIsNotValid: "errors.clientIdIsNotValid",
    routeIdIsNotValid: "errors.routeIdIsNotValid",
    storyIdIsNotValid: "errors.storyIdIsNotValid",
    redirectingIn: "errors.redirectingIn",
  };
}
