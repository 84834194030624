import { FC, useCallback } from "react";
import { AnalyticList } from "@/components/AnalyticList";
import { IRoute } from "@/features/routes/types";
import { IProjectRoute } from "@/features/projects/types";
import { useAnalytic } from "@/hooks/useAnalytic";
import { WordKeys } from "@/constants/WordKeys";
import { PlayButton } from "@/components/PlayButton";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "@/hooks/useSearchParams";
import { ISearchParams } from "@/types/searchParams";
import { MarkdownText } from "@/components/MarkdownText";
import classNames from "classnames";
import dialogStyles from "@/styles/DialogContent.module.scss";
import styles from "./RouteDialog.module.scss";

interface IRouteDialogProps {
  className?: string;
  isPlayBtn?: boolean;
  onClose?: () => void;
  routeData: IRoute | IProjectRoute;
}

export const RouteDialog: FC<IRouteDialogProps> = ({
  className = "",
  isPlayBtn,
  onClose,
  routeData,
}) => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const { t } = useTranslation();
  const { currentAnalytic } = useAnalytic(
    routeData?._id,
    routeData?.stories?.length,
  );
  const { projectId, routeId, isPlay, playInRow } = parsedSearchParams;

  const handlePlayRoute = useCallback(() => {
    mergeSearchParams({
      projectId: projectId,
      routeId: routeId ? routeData?._id.toString() : undefined,
      storyId: routeData?.stories[0]?.id,
      playInRow: true,
      isPlay: !isPlay || !playInRow ? true : undefined,
    });
  }, [
    isPlay,
    mergeSearchParams,
    playInRow,
    projectId,
    routeData?._id,
    routeData?.stories,
    routeId,
  ]);

  return (
    <div className={classNames(dialogStyles.root, className)}>
      <img
        className={dialogStyles.img}
        src={routeData?.image_path}
        alt={"Active route"}
      />
      <div className={dialogStyles.body}>
        <div className={dialogStyles.header}>
          <h4 className={styles.title}>{routeData.name}</h4>
          <div className={dialogStyles.boxSb}>
            <AnalyticList
              className={styles.analytic}
              analytics={currentAnalytic}
              countText={WordKeys.analytic.routesCount}
              routeId={routeData?._id}
            />
            {isPlayBtn && (
              <PlayButton
                isPlay={
                  isPlay &&
                  playInRow &&
                  (!routeId || routeId === routeData?._id)
                }
                name={t(WordKeys.routeView.playAll)}
                onClick={handlePlayRoute}
              />
            )}
          </div>
        </div>
        <div className={dialogStyles.description}>
          <MarkdownText
            description={routeData.description}
            action={onClose}
          ></MarkdownText>
        </div>
      </div>
    </div>
  );
};
