import React, { FC, useCallback, useMemo } from "react";
import { WordKeys } from "@/constants/WordKeys";
import { ProjectList } from "@/features/projects/components/ProjectList";
import { IClientProject } from "@/features/client/types";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "@/hooks/useWindowSize";
import { Link } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import HomeIcon from "@/img/icons/home.svg";
import ChevronIcon from "@/img/icons/chevron_right.svg";
import CloseIcon from "@/img/icons/close_gray.svg";
import classNames from "classnames";
import styles from "./Sidebar.module.scss";

interface ISidebarProps {
  isOpen: boolean;
  onSwitchSidebar: () => void;
  isLoading: boolean;
  projects?: IClientProject[];
  clientId?: string;
}

export const Sidebar: FC<ISidebarProps> = ({
  isOpen,
  onSwitchSidebar,
  isLoading,
  clientId,
  projects,
}) => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();

  const handleStopPropagation = useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  const isOpenProps = useMemo(() => {
    return isMobile ? true : isOpen;
  }, [isMobile, isOpen]);

  return (
    <div
      className={classNames(isOpen || styles.closed, styles.root)}
      onClick={onSwitchSidebar}
    >
      <div className={styles.wrapper} onClick={handleStopPropagation}>
        <div className={styles.head}>
          <button
            className={classNames(styles.btn, styles.switchBtn)}
            onClick={onSwitchSidebar}
          >
            <img
              className={styles.switchIcon}
              src={ChevronIcon}
              alt={"icon: arrow"}
            />
            <img
              className={styles.closeIcon}
              src={CloseIcon}
              alt={"icon: close"}
            />
          </button>
          <Link
            to={ROUTES.main.createURL(clientId || "")}
            onClick={() =>
              isMobile && projects?.length === 1 && onSwitchSidebar()
            }
            className={classNames(styles.homeBtn, styles.btn)}
          >
            <img
              className={styles.homeIcon}
              src={HomeIcon}
              alt={"icon: home"}
            />
          </Link>
          <h6 className={styles.header}>{t(WordKeys.sidebar.projects)}</h6>
        </div>

        {!isLoading && (
          <ProjectList
            isOpen={isOpenProps}
            t={t}
            onSwitchSidebar={onSwitchSidebar}
            projects={projects}
          />
        )}
      </div>
    </div>
  );
};
