import { FC, useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";
import { getMarkerIcon } from "@/features/map/components/StoryMarker/getMarkerIcon";
import StoryMarker from "@/img/icons/play.svg";
import classNames from "classnames";
import styles from "../StoryMarker/StoryMarker.module.scss";

interface ISingleStoryMarkerProps {
  coordinate: google.maps.LatLngLiteral;
  isPlaying?: boolean;
  mergeSearchParams: any;
}

export const SingleStoryMarker: FC<ISingleStoryMarkerProps> = ({
  coordinate,
  isPlaying,
  mergeSearchParams,
}) => {
  const handleClick = useCallback(() => {
    mergeSearchParams({ isPlay: !isPlaying || undefined });
  }, [isPlaying, mergeSearchParams]);

  return (
    <AdvancedMarker
      className={styles.root}
      position={coordinate}
      onClick={handleClick}
    >
      {getMarkerIcon({
        className: styles.icon,
        fill: "#266BEC",
      })}
      <div className={classNames(styles.content, isPlaying && styles.playing)}>
        {isPlaying ? (
          <>
            <span></span>
            <span></span>
            <span></span>
          </>
        ) : (
          <img
            className={styles.smallIcon}
            src={StoryMarker}
            alt={"icon: Single Story Marker"}
          />
        )}
      </div>
    </AdvancedMarker>
  );
};
