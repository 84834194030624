import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setAnalyticData } from "@/store/analyticSlice";
import { useGetStoryByIdQuery } from "@/features/stories/storyApi";
import { useLoadingOverlay } from "@/hooks/useLoadingOverlay";
import { handleError } from "@/helpers/handleError";

export const useStoryPage = (storyId: string) => {
  const dispatch = useDispatch();
  const { toggleLoadingOverlay } = useLoadingOverlay();

  const { data, isLoading, error } = useGetStoryByIdQuery(
    { storyId },
    { skip: !storyId },
  );

  useEffect(() => {
    toggleLoadingOverlay(isLoading);
  }, [isLoading, toggleLoadingOverlay]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      dispatch(
        setAnalyticData([
          {
            id: data?._id,
            views: data?.plays,
            share: data?.shares,
          },
        ]),
      );
    }
  }, [data, dispatch]);

  return {
    data,
    error,
  };
};
