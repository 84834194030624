import { FC, useEffect } from "react";
import { TopBar } from "@/components/TopBar";
import { RouteView } from "@/features/routes/RouteView";
import { useParams } from "react-router-dom";
import { MapView } from "@/features/map/MapView";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useActiveData } from "@/hooks/useActiveData";
import { useWindowSize } from "@/hooks/useWindowSize";
import { RouteActive } from "@/features/routes/components/RouteActive";
import { ISearchParams } from "@/types/searchParams";
import classNames from "classnames";
import styles from "./RoutePage.module.scss";

interface IRoutePageProps {
  singlePage?: boolean;
}

export const RoutePage: FC<IRoutePageProps> = ({ singlePage = true }) => {
  const { isMobile } = useWindowSize();
  const { routeId } = useParams();
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<ISearchParams>();
  const { route } = useActiveData();

  useEffect(() => {
    mergeSearchParams({ playInRow: true }, true);
  }, []);

  return (
    <div
      className={classNames(
        styles.root,
        parsedSearchParams?.storyId && styles.active,
        singlePage && styles.pd,
      )}
    >
      <TopBar className={styles.topBar} title={route?.name || ""} />
      {isMobile && (
        <RouteActive
          className={styles.routeActive}
          routeData={route || undefined}
        />
      )}
      <div className={styles.container}>
        <RouteView
          className={styles.content}
          routeId={routeId}
          redirectWhenError={true}
        />
        <MapView
          className={classNames(styles.content, styles.map)}
          routeId={routeId}
          storyId={parsedSearchParams?.storyId}
        />
      </div>
    </div>
  );
};
